import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { TeamsData } from "../pages/Matches";
import { fetchFromApi } from "../utils/api";
import { team_names_equal } from "../utils/teams";
import { RoundMetadata, Side } from "../utils/types";
import TeamIcon from "./TeamIcon";
import styles from './TeamInfo.module.css';

const TeamInfo = ({roundMetadata, side, minimal} : {
  roundMetadata: RoundMetadata | undefined,
  side: Side,
  minimal: boolean,
  }) => {

    if(minimal) {
      return null;
    }

    if(roundMetadata !== undefined) {
      const teamKey = side === Side.CT ? roundMetadata.ct_team_key : roundMetadata.t_team_key;
      const teamName = side === Side.CT ? roundMetadata.ct_team : roundMetadata.t_team;
      const teamScore = side === Side.CT ? roundMetadata.ct_score : roundMetadata.t_score;
      return (
        <div className={`${styles.info} ${styles.teamInfo} rounded shadow`}>
          <div className={`${styles.teamInfoWrapper} mx-1 my-auto`}>
            <div className="flex-grow-1">
              <a href={`/filter?team=${teamKey}&mapname=${roundMetadata.mapname}`} className={styles.teamInfoLink}>
                <span className="d-flex align-items-center">
                  <TeamIcon size={20} name={teamKey} flag={undefined} />
                  <span className="ms-1">{teamName.toUpperCase()}</span>
                  <i className={`bi bi-search ${styles.teamInfoHoverIcon}`} style={{color: '#888', marginLeft: '10px'}}></i>
                </span>
              </a>
            </div>
            <div>
              {teamScore}
            </div>
          </div>
        </div>
      )
    }
    return <div className={`${styles.info} ${styles.teamInfo} rounded shadow`}>
      <div className={`${styles.teamInfoWrapper} m-1 mx-auto my-auto`}>
        <Spinner animation="border" style={{color: "#666"}}/>
      </div>
    </div>
  }

export default TeamInfo;