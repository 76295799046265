import { Spinner } from "react-bootstrap";
import { primaryWeaponsSvg, secondaryWeaponsSvg, utilityItemsSvg } from "../utils/weapons";
import { InventoryItem, PlayerFrameData, Side } from "../utils/types";
import styles from './Player.module.css';

function compareInventory(a: InventoryItem, b: InventoryItem) {
  if (a.weaponName < b.weaponName) {
    return -1;
  }
  if (a.weaponName > b.weaponName) {
    return 1;
  }
  return 0;
}

const LoadingText = () => (
  <>
    <Spinner 
      animation="border" 
      style={{ 
        color: "#666",
        width: "0.7rem",
        height: "0.7rem",
        borderWidth: "0.1em",
        marginRight: "6px"
      }} 
    />
    Loading...
  </>
);

const Player = ({ data, side, minimal, hovered, csVersion }: {
  data: PlayerFrameData | undefined,
  side: Side,
  minimal: boolean,
  hovered: boolean,
  csVersion: string | undefined,
}) => {
  if (data === undefined) {
    if (minimal) {
      return (
        <div className={`${styles.info} ${styles.infoMinimal} ${styles.playerInfo} ${styles.playerInfoMinimal} ${styles.playerInfoDead}`}>
          <div className={styles.healthContainer}>
            <div className={`${styles.healthBackground} ${styles.healthBackgroundLoading}`} />
            <div className={`${styles.contentContainerMinimal}`}>
              <div className={styles.playerNameCashContainer}>
                <div className={`${styles.playerName} d-flex align-items-center`}>
                  <LoadingText />
                </div>
                <div className={styles.playerCashMinimal}></div>
              </div>
              <div className={styles.weaponsContainer}>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={`${styles.info} ${styles.playerInfo} ${styles.playerInfoDead}`}>
        <div className={styles.healthContainer}>
          <div className={`${styles.healthBackground} ${styles.healthBackgroundLoading}`} />
          <div className={`${styles.contentContainer} d-flex`}>
            <div className={`${styles.playerName} flex-grow-1 d-flex align-items-center`}>
              <LoadingText />
            </div>
            <div className={styles.playerCash}>$0</div>
          </div>
        </div>
        <div className="d-flex">
          <i className={`bi bi-shield ${styles.armorIcon}`}></i>
          <div className="flex-grow-1">
            <div className={`${styles.weaponIcon} ${styles.weaponIconNeutral}`}></div>
          </div>
          <div className="flex-grow-1">
          </div>
          <div>
          </div>
        </div>
      </div>
    );
  }

  const deadClass = data.isAlive ? '' : styles.playerInfoDead;
  const weaponIconClass = styles.weaponIconNeutral;
  const healthBgClass = side === Side.CT ? styles.healthBackgroundCT : styles.healthBackgroundT;
  
  let primaryWeapon = data.inventory ? data.inventory.find(item => item.weaponName in primaryWeaponsSvg)?.weaponName : undefined;
  let secondary = data.inventory ? data.inventory.find(item => item.weaponName in secondaryWeaponsSvg)?.weaponName : undefined;
  let weapon = primaryWeapon !== undefined ? primaryWeapon : secondary !== undefined ? secondary : undefined;
  let weaponSvg = weapon !== undefined ? (weapon in primaryWeaponsSvg ? primaryWeaponsSvg[weapon] : secondaryWeaponsSvg[weapon]) : undefined;
  let utilities = data.inventory ? data.inventory.sort(compareInventory).filter(item => item.weaponName in utilityItemsSvg) : [];

  if (csVersion === "cs2") {
    utilities = utilities.filter(item => item.weaponName !== "C4");
  }

  if (minimal) {
    return (
      <div className={`${styles.info} ${styles.infoMinimal} ${styles.playerInfo} ${styles.playerInfoMinimal} ${deadClass}`}>
        <div className={styles.healthContainer}>
          <div 
            className={`${styles.healthBackground} ${healthBgClass}`} 
            style={{ width: `${data.hp}%` }}
          />
          <div className={`${styles.contentContainerMinimal}`}>
            <div className={styles.playerNameCashContainer}>
              <div className={styles.playerName}>{data.name}</div>
              <div className={styles.playerCashMinimal}>${data.cash}</div>
            </div>
            <div className={styles.weaponsContainer}>
              {data.isAlive && utilities.map((item, index) => {
                const totalAmmo = csVersion === "cs2" ? (item.ammoInMagazine) : (item.ammoInMagazine + item.ammoInReserve);
                return [...Array(totalAmmo)].map((_, i) => (
                  <img
                    key={`${item.weaponName}-${i}`}
                    src={`${process.env.PUBLIC_URL}/items/${utilityItemsSvg[item.weaponName]}`}
                    alt={item.weaponName}
                    className={`${styles.weaponIcon} ${weaponIconClass}`}
                  />
                ));
              })}
              {weaponSvg && data.isAlive ? 
                <img src={`${process.env.PUBLIC_URL}/items/${weaponSvg}`} alt={weapon} className={`${styles.weaponIcon} ${weaponIconClass}`} /> : 
                weapon
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`${styles.info} ${styles.playerInfo} ${deadClass}`}>
      <div className={styles.healthContainer}>
        <div 
          className={`${styles.healthBackground} ${healthBgClass}`} 
          style={{ width: `${data.hp}%` }}
        />
        <div className={`${styles.contentContainer} d-flex`}>
          <div className={`${styles.playerName} flex-grow-1`}>{data.name}</div>
          <div className={styles.playerCash}>${data.cash}</div>
        </div>
      </div>
      <div className="d-flex">
        {data.isAlive && data.armor === 0 && !data.hasHelmet && <i className={`bi bi-shield ${styles.armorIcon}`}></i>}
        {data.isAlive && data.armor > 0 && !data.hasHelmet && <i className={`bi bi-shield-shaded ${styles.armorIcon}`}></i>}
        {data.isAlive && data.armor > 0 && data.hasHelmet && <i className={`bi bi-shield-fill ${styles.armorIcon}`}></i>}
        <div className="flex-grow-1">
          {weaponSvg && data.isAlive ?
            <img src={`${process.env.PUBLIC_URL}/items/${weaponSvg}`} alt={weapon} className={`${styles.weaponIcon} ${weaponIconClass}`} /> :
            <div className={`${styles.weaponIcon} ${weaponIconClass}`}></div>
          }
        </div>
        <div className="flex-grow-1">
        </div>
        <div>
          {data.hasBomb && data.isAlive ? <img src={`${process.env.PUBLIC_URL}/items/weapon_c4.svg`} alt={weapon} className={`${styles.weaponIcon} ${weaponIconClass}`} /> : null}
          {data.hasDefuse ? <img src={`${process.env.PUBLIC_URL}/items/item_defuser.svg`} alt={weapon} className={`${styles.weaponIcon} ${weaponIconClass}`} /> : null}
          {utilities.map((item, index) => {
            const totalAmmo = csVersion === "cs2" ? (item.ammoInMagazine) : (item.ammoInMagazine + item.ammoInReserve);

            const images = [];
            for (let i = 0; i < totalAmmo; i++) {
              images.push(
                <img
                  key={`${item.weaponName}-${i}`}
                  src={`${process.env.PUBLIC_URL}/items/${utilityItemsSvg[item.weaponName]}`}
                  alt={item.weaponName}
                  className={`${styles.weaponIcon} ${weaponIconClass}`}
                />
              );
            }
            return data.isAlive ? images : [];
          })}
        </div>
      </div>
    </div>
  );
}

export default Player;