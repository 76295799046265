import { useState, useEffect, useRef } from 'react';
import styles from './Notes.module.css';
import { Note, RoundMetadata } from '../utils/types';
import { fetchFromApi } from '../utils/api';

interface NotesProps {
  currentTick: number;
  startTick: number;
  tickRate: number;
  currentRound?: RoundMetadata;
  onChangeTick: (tick: number) => void;
}

export const Notes = ({
  currentTick,
  startTick,
  tickRate,
  currentRound,
  onChangeTick
}: NotesProps) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [activeNote, setActiveNote] = useState<Note | null>(null);
  const [editingNote, setEditingNote] = useState<Note | null>(null);
  const [loading, setLoading] = useState(true);
  const [editingContent, setEditingContent] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isNewNote, setIsNewNote] = useState(false);

  // Load notes when currentRound changes
  useEffect(() => {
    const loadNotes = async () => {
      if (!currentRound) return;
      
      setLoading(true);
      try {
        const response = await fetchFromApi(`/notes?match_id=${currentRound.match_id}&mapname=${currentRound.mapname}&roundnum=${currentRound.roundnum}`);
        const data = await response.json();
        setNotes(data);
        // Set first note as active if exists
        setActiveNote(data.length > 0 ? data[0] : null);
      } catch (error) {
        console.error('Failed to load notes:', error);
      } finally {
        setLoading(false);
      }
    };

    loadNotes();
    setEditingNote(null);
    setEditingContent('');
  }, [currentRound]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  const formatNoteTime = (noteTick: number): string => {
    const elapsedTicks = noteTick - startTick;
    const elapsedSeconds = Math.floor(elapsedTicks / tickRate);
    
    if (elapsedSeconds >= 0) {
      const remainingTime = Math.max(0, 115 - elapsedSeconds);
      return formatTime(remainingTime);
    } else {
      const freezeTimeElapsed = elapsedSeconds * -1;
      return formatTime(freezeTimeElapsed);
    }
  };

  useEffect(() => {
    if (editingNote && textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(editingContent.length, editingContent.length);
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [editingNote]);

  const handleStartEdit = (note: Note) => {
    setEditingNote(note);
    setEditingContent(note.note);
    onChangeTick(note.tick);
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
      }
    }, 0);
  };

  const handleSave = async () => {
    if (!currentRound || !editingNote) return;

    const updatedNote = {
      ...editingNote,
      note: editingContent
    };

    // Update UI immediately
    setNotes(prev => prev.map(n => 
      n.note_id === editingNote.note_id ? updatedNote : n
    ));
    setActiveNote(updatedNote);
    setEditingNote(null);
    setEditingContent('');
    setIsNewNote(false);

    // Send to backend
    try {
      await fetchFromApi('/notes', {
        method: 'POST',
        body: JSON.stringify(updatedNote)
      });
    } catch (error) {
      console.error('Failed to save note:', error);
    }
  };

  const handleCancel = () => {
    if (isNewNote && editingNote) {
      // Remove the new note from the list
      setNotes(prev => prev.filter(n => n.note_id !== editingNote.note_id));
      setActiveNote(notes.length > 1 ? notes[0] : null);
    }
    setEditingNote(null);
    setEditingContent('');
    setIsNewNote(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (editingNote) {
      e.stopPropagation();
      if (e.key === 'Enter' && e.ctrlKey) {
        handleSave();
      } else if (e.key === 'Escape') {
        handleCancel();
      }
    }
  };

  const handleDelete = async () => {
    if (!activeNote || !currentRound) return;

    // Update UI immediately
    const newNotes = notes.filter(n => n.note_id !== activeNote.note_id);
    setNotes(newNotes);
    setActiveNote(newNotes.length > 0 ? newNotes[0] : null);

    // Send to backend
    try {
      await fetchFromApi(`/notes?match_id=${currentRound.match_id}&note_id=${activeNote.note_id}`, {
        method: 'DELETE'
      });
    } catch (error) {
      console.error('Failed to delete note:', error);
    }
  };

  const handleAddNote = () => {
    if (!currentRound) return;

    // Find a unique tick by incrementing until we find an unused one
    let uniqueTick = Math.floor(currentTick);
    while (notes.some(note => note.tick === uniqueTick)) {
      uniqueTick++;
    }

    const newNote: Note = {
      note_id: crypto.randomUUID(),
      match_id: currentRound.match_id,
      mapname: currentRound.mapname,
      roundnum: currentRound.roundnum,
      tick: uniqueTick,
      note: ''
    };

    // Update UI immediately
    setNotes(prev => [...prev, newNote]);
    setActiveNote(newNote);
    setEditingNote(newNote);
    setEditingContent('');
    setIsNewNote(true);
    onChangeTick(uniqueTick);

  };

  const navigateToNote = (note: Note) => {
    setActiveNote(note);
    onChangeTick(note.tick);
  };

  useEffect(() => {
    if (notes.length === 0 || editingNote) return;

    const sortedNotes = [...notes].sort((a, b) => a.tick - b.tick);
    
    // Find the last note that occurred before or at the current tick
    const lastPastNote = sortedNotes.reduce((closest, note) => {
      if (note.tick <= currentTick && (!closest || note.tick > closest.tick)) {
        return note;
      }
      return closest;
    }, null as Note | null);

    // If we found a note in the past, use it
    if (lastPastNote) {
      setActiveNote(lastPastNote);
    } else {
      // If no notes in the past, use the first future note
      setActiveNote(sortedNotes[0]);
    }
  }, [currentTick, notes, editingNote]);

  if (loading) {
    return (
      <div className={styles.notesContainer}>
        <div className={styles.emptyState}>
          <div className={styles.spinner} />
          Loading notes...
        </div>
      </div>
    );
  }

  if (notes.length === 0) {
    return (
      <div className={styles.notesContainer}>
        <div className={styles.emptyState} onClick={handleAddNote}>
          <i className="bi bi-journal-text" />
          Add note
        </div>
      </div>
    );
  }

  if (!activeNote) return null;

  const sortedNotes = [...notes].sort((a, b) => a.tick - b.tick);
  const currentIndex = sortedNotes.findIndex(note => note.note_id === activeNote.note_id);

  return (
    <div className={styles.notesContainer} onKeyDown={handleKeyDown}>
      <div className={styles.noteHeader}>
        <div 
          className={`${styles.noteTime} ${editingNote ? styles.noteTimeEditing : ''}`}
          onClick={() => onChangeTick(activeNote.tick)}
          title="Jump to note time"
        >
          {formatNoteTime(activeNote.tick)}
        </div>
        <div className={styles.noteNavigation}>
          <button 
            className={styles.navButton}
            disabled={currentIndex === 0}
            onClick={() => navigateToNote(sortedNotes[currentIndex - 1])}
            title="Previous note"
          >
            &lt;
          </button>
          <span className={styles.notePosition}>
            {currentIndex + 1}/{sortedNotes.length}
          </span>
          <button 
            className={styles.navButton}
            disabled={currentIndex === sortedNotes.length - 1}
            onClick={() => navigateToNote(sortedNotes[currentIndex + 1])}
            title="Next note"
          >
            &gt;
          </button>
        </div>
      </div>

      <div 
        className={editingNote ? styles.noteContentEditing : styles.noteContent}
        onClick={() => onChangeTick(activeNote.tick)}
        title="Jump to note time"
      >
        {editingNote ? (
          <textarea
            ref={textareaRef}
            value={editingContent}
            onChange={(e) => setEditingContent(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Write a note..."
          />
        ) : (
          <div className={styles.noteText}>
            {activeNote.note}
          </div>
        )}
      </div>

      <div className={styles.noteFooter}>
        {editingNote ? (
          <>
            <div className="flex-grow-1" />
            <button className={`${styles.footerButton} ${styles.primary}`} onClick={handleSave}
              title="Save note (Ctrl+Enter)"
            >
              <i className="bi bi-check2" />
            </button>
            <button className={styles.footerButton} onClick={handleCancel}
              title="Cancel editing (Esc)"
            >
              <i className="bi bi-x-lg" />
            </button>
          </>
        ) : (
          <>
            <div className="flex-grow-1" />
            <button className={styles.footerButton} onClick={() => handleStartEdit(activeNote)}
              title="Edit note"
            >
              <i className="bi bi-pencil" />
            </button>
            <button className={`${styles.footerButton} ${styles.danger}`} onClick={handleDelete}
              title="Delete note"
            >
              <i className="bi bi-trash" />
            </button>
            <button className={`${styles.footerButton} ${styles.primary}`} onClick={handleAddNote}
              title="Add new note"
            >
              <i className="bi bi-plus-circle" />
            </button>
          </>
        )}
      </div>
    </div>
  );
}; 