export const team_logos = [
  '-600',
  '-72c',
  '-axecty',
  '-high5',
  '-illhouse',
  '-konoha-',
  '-midnight-',
  '-n4shdv0r',
  '-org-',
  '-paralympics-',
  '-quick-hands-',
  '-redliners',
  '-rushingbsite-',
  '-the-binmen-',
  '-xsv-clan-',
  '00-nation-prospects',
  '00-nation-talent',
  '00-nation',
  '00-prospects',
  '00-talent',
  '00nation-talent',
  '00nation',
  '00prospects',
  '01',
  '05-b4zuk4',
  '0chance-',
  '100-thieves',
  '100monawek',
  '100pg',
  '100pinggods',
  '1017-y-9es',
  '10avg',
  '11hoods',
  '1337',
  '1337uania',
  '15averagegang',
  '16-aces',
  '1620-kings',
  '1800-gang',
  '1asteven-seagal',
  '1dayheroes',
  '1for1',
  '1hp-gg',
  '1hp',
  '1oavg',
  '1shot',
  '1wesuck1',
  '1win-academy',
  '1win',
  '2-6-hog-cycle',
  '23club',
  '24bhb',
  '2990-esports',
  '2d2-team',
  '2ez',
  '2game',
  '2gi3ldon',
  '2h4u',
  '2kill-gaming',
  '2ndratetalent',
  '2vinesardine',
  '2zoomer3boomer',
  '300iqstack',
  '30yo-talents',
  '32',
  '335cg-m88',
  '3dmax',
  '3k-esport',
  '3k-raiderio',
  '3k',
  '3leggedfreaks',
  '3sup-enterprises',
  '4botsplusrelic',
  '4dk',
  '4elements',
  '4glory',
  '4kings',
  '4ormula',
  '4ztankuamiley',
  '5-bots',
  '5-goblinz',
  '5-guys',
  '5-in-pit',
  '5-randoms',
  '500',
  '500x',
  '55th-st-crippin',
  '5balls',
  '5doigts',
  '5fyctimz',
  '5hp',
  '5iveonit',
  '5k-academy',
  '5m-esports',
  '5manrush',
  '5mid',
  '5power-gaming',
  '5power',
  '5randoms',
  '6-foot-in-airforces',
  '6479',
  '6f-esports',
  '6o8',
  '6sense',
  '7-deadly-zyns',
  '7-heaven',
  '716-bangerz',
  '731',
  '777',
  '7am',
  '7dsins',
  '88-bobsled',
  '8ballers',
  '8ight',
  '9-pandas-esports',
  '9-pandas',
  '95vikings',
  '96-bulls',
  '99-percent',
  '99dmgban',
  '9ine-academy',
  '9ine',
  '9pandasfearless',
  '9z-academy',
  '9z-t-asia',
  '9z-team',
  '9z',
  'a-losers',
  'a27',
  'aab-elite',
  'aab-esport',
  'aab-female',
  'aab',
  'aalborg-rebels',
  'aao',
  'ab',
  'able-esports',
  'absent-minded',
  'absolute-legends',
  'abt',
  'abuelos-locos',
  'academy-of-esports',
  'accusata',
  'ace-gaming',
  'acerbus',
  'aces',
  'achievo-esports',
  'acrux',
  'actic-esports',
  'activated',
  'activit-e-csgo',
  'activit-e',
  'ad-hoc',
  'adactiveg',
  'adagio',
  'adaptation',
  'adepts',
  'adive',
  'adnzpeek',
  'adult-swim',
  'adv-cs',
  'advance-esports',
  'advanced-pause',
  'aelg',
  'aequus-club',
  'aereq-one',
  'aero',
  'aether',
  'aethers-guard',
  'aex-1',
  'affirmative',
  'afking',
  'after',
  'afu',
  'against-all-authority',
  'age-academy',
  'age-sports',
  'agency',
  'agf-academy',
  'agf',
  'ago-esports',
  'ago-gaming',
  'ago',
  'ahjo',
  'aim2main',
  'aimassassins',
  'aimoggers',
  'airborne',
  'airlya',
  'ajax-esports',
  'ajf-e-sports',
  'ajf',
  'ajuri',
  'akatsuki',
  'akave',
  'akimbo-esports',
  'akopalipsa',
  'albanianboyzinc',
  'algorithm-16',
  'aligon',
  'alke',
  'all-gamers',
  'all-gas-no-brakes',
  'alleyeson',
  'alliance',
  'allstars-',
  'allstars',
  'allstarsacademy',
  'alltech-esports',
  'alma',
  'alpha-gaming',
  'alpha-red',
  'alpha-united',
  'alpha',
  'alsen-team',
  'altered-edge',
  'alternate-attax',
  'altyra',
  'alumni',
  'always-365',
  'amazing-gaming',
  'ambidex',
  'ambush-e-sport',
  'ambush-esport',
  'ambush-fe',
  'ambush',
  'ameme',
  'amkal',
  'amnesia',
  'amo-esport',
  'amo-esports',
  'amo',
  'amoebits',
  'amped-esports',
  'amplicity',
  'amsterdam-kings',
  'ancient-evil',
  'ancient-globals',
  'ancient',
  'andrew-piano-man-aclegi',
  'andromeda',
  'andromedagaming',
  'anexis-esports',
  'animal-squad',
  'animosity',
  'ankaramessi',
  'anlaki',
  'anonymo-academy',
  'anonymo',
  'anorthosis-famagusta',
  'anox',
  'antic',
  'antimaslo',
  'antwerpaces',
  'any-baggers',
  'aorus-vision',
  'aox',
  'apek',
  'apeks-rebels',
  'apeks',
  'aperture-gaming',
  'apes',
  'apocalypse',
  'apologis-esport',
  'apologis',
  'aporia',
  'aquila',
  'aravt',
  'arcade-academy',
  'arcade-esports',
  'arcade',
  'arcana',
  'arcane-wave',
  'arcane',
  'archangel5',
  'archangels',
  'arcred',
  'arctic-ratz',
  'arctic',
  'arcy',
  'are-you-kidding',
  'area-51-gaming',
  'area-gaming',
  'arena-bulls',
  'arena-jogue-facil',
  'ares-female',
  'argon',
  'argument-clinic',
  'arianas-kittens',
  'aristocracy',
  'arka-gdynia',
  'arkangel',
  'armann',
  'armo',
  'arpento',
  'arrmy',
  'arrow-nxt',
  'arrow-reformed',
  'arrow',
  'art-of-cybergame',
  'artemis-esports',
  'artyk-gaming',
  'arvum',
  'asap',
  'ascend',
  'ascension',
  'ascent',
  'aseris',
  'asg',
  'asianstar',
  'asking-questions',
  'asphalt-company',
  'aspyre',
  'assos-purple',
  'astana-dragons',
  'asterion-csgo',
  'asterion',
  'asterius-gaming',
  'asterys',
  'astony',
  'astral-aces',
  'astral-influence',
  'astralis-',
  'astralis-talent',
  'astralis',
  'asuel',
  'at-gaming',
  'atb-gaming',
  'athena',
  'athletico-bh',
  'athletico-esports',
  'athletico',
  'atk-academy',
  'atk-oshun',
  'atk',
  'atlando',
  'atlantic',
  'atlanticesports',
  'atlantis-esports',
  'atlantis',
  'atlas-esports',
  'atlas',
  'atmosphere',
  'atom',
  'atomic-gaming',
  'atox',
  'atrapa2',
  'auc-tigers',
  'audacity',
  'augaming',
  'august',
  'aura-uk',
  'aura',
  'aurora-young-blood',
  'aurora-young-blud',
  'aurora',
  'australs',
  'austrian-force-willhaben',
  'avangar-1xbet',
  'avangar',
  'avant-gaming',
  'avant-garde',
  'avant',
  'avantiura',
  'avasive-fe',
  'avasive',
  'ave',
  'avers',
  'avez-casters',
  'avez',
  'avocado-gelato',
  'avt',
  'awpenheimer',
  'awpnt',
  'awsomniac',
  'axies',
  'ayayayaya',
  'azio-esports',
  'azor',
  'azp-esport',
  'azym',
  'b',
  'b',
  'b',
  'b00mer5',
  'b00sted-bums',
  'b2tg-kingz',
  'b4-esports',
  'b4-fe',
  'b4',
  'b8',
  'babos',
  'back-n-my-prime',
  'back2back',
  'backdoor-bandit',
  'backin1969',
  'backnforth',
  'backshot-gaming',
  'bad-but-bored',
  'bad-esports',
  'bad-manners',
  'bad-news-balds',
  'bad-news-bears',
  'bad-news-eagles',
  'bad-news-panthers',
  'badass',
  'badcompany',
  'baecon-gaming-group',
  'baecon-gg',
  'baecon',
  'baecongg',
  'bagel-boiis',
  'baiters',
  'bajini-puleni',
  'baked-in-canada',
  'baks',
  'balenciaga',
  'balkan-bears',
  'ballistic',
  'balloon-animals',
  'balls',
  'baltika',
  'bandits',
  'banger-gang',
  'banging-moms',
  'bankai',
  'bankapepsi',
  'banneret',
  'bantz',
  'bare-min-bandit',
  'barelymademain',
  'barelymain',
  'baskonia-esports',
  'bazar-mix',
  'bbb',
  'bbqrew',
  'bd',
  'bdr',
  'be-all-end-all',
  'be-mix',
  'be-together-win',
  'beaniehut-nade-prac',
  'bears-concept',
  'bears',
  'beastbagchasers',
  'beboldgg',
  'bee',
  'beer-and-cigs',
  'beermoney',
  'begenius-esc',
  'begrip-gaming',
  'begrip',
  'bekescsabai-egyesulet',
  'bekescsabai',
  'ben-clan',
  'benamisos',
  'bence',
  'beneath',
  'benimobroderna',
  'berzerk',
  'besiktas',
  'bestia',
  'betafarmers',
  'betboom',
  'betera',
  'beyond-esports',
  'beyond',
  'bez-zp',
  'bh3-esports',
  'bh9k',
  'bhop',
  'bifrost',
  'big-5-esports',
  'big-5',
  'big-academy',
  'big-addy-daddys',
  'big-beefers',
  'big-chillin',
  'big-deag-energy',
  'big-equipa',
  'big-frames',
  'big-gods',
  'big-omen-academy',
  'big',
  'bigbrainnoaim',
  'bigdirtyesports',
  'biggamemen',
  'biggiecheesefanclub',
  'bigodes',
  'bigpharma',
  'bigtime-regal',
  'bike-thieves',
  'binary-dragons',
  'bing-bong',
  'binger-kong',
  'birminghamsalvo',
  'biscuitsngravey',
  'bit-esports',
  'bit',
  'bitfix',
  'bitfixgaming',
  'bittersweet',
  'bizarre-gaming',
  'bizarre',
  'bjornbrothers2',
  'bl4ze',
  'black-dragon-society',
  'black-dragon',
  'black-dragons-fe',
  'black-dragons',
  'blackhat-esport',
  'blacklaminate',
  'blackouted',
  'blacksea',
  'blazing-parrots',
  'blazingstar',
  'bleed',
  'bleiz',
  'blight-gaming',
  'blink',
  'blnk',
  'blood-torva',
  'bloodpro-esports',
  'bloody-crystal',
  'bloons-tower-defense-6',
  'blue-hebrews',
  'blue-killer',
  'blue-lock',
  'bluejays-agency',
  'bluejays-empire',
  'bluejays-royal',
  'bluejays-zoo',
  'bluejays',
  'bluestones',
  'blvkhvnd',
  'bm-esports',
  'bnp',
  'bo4ka-piva',
  'boathousev2',
  'boca-juniors',
  'bom-tempo',
  'bongoneiro',
  'boogie-boyz',
  'boom',
  'boomer-gang',
  'boomer-squad',
  'boomesports',
  'boomshake',
  'boomslang-gang',
  'boop-boop-gang',
  'boosting-piksuk',
  'boot',
  'booty-sniffas',
  'bootybroacademy',
  'booz-cluez',
  'born-in-mongolia',
  'born-of-fire',
  'born-to-fluke',
  'born2throw',
  'bottomfeeders',
  'bounty-hunters',
  'bpcsgo',
  'bpro-gaming',
  'brahmas',
  'brain-fog',
  'bravado-gaming',
  'bravado',
  'brave-soldiers',
  'bravos-gaming',
  'bravos',
  'brazen',
  'bread-bandits',
  'breakaway-esports',
  'breakaway',
  'breakthrough',
  'breidablik',
  'bren',
  'brisbane-deceptors',
  'brocks-birds',
  'broda',
  'brodyyyginr',
  'broken-e-sport',
  'brothermans-',
  'brugge',
  'brussels-guardians',
  'brusselsprout',
  'brute-academy',
  'brute-evolution',
  'brute-juniors',
  'brute',
  'brzcas',
  'btc-bandits',
  'bth',
  'btrg',
  'bubibabu',
  'bucce-force',
  'buck-nasty',
  'budapest-5',
  'budapest-five',
  'budapest5',
  'buddha-pests',
  'buds',
  'buff-chick-peet',
  'buff-nuts68',
  'buffalo',
  'bugatti-music',
  'buk-choi',
  'bulldozer-esports',
  'bulls-95',
  'bunker-esport',
  'bunkkerigg',
  'bunny-boys',
  'bushido-boyz',
  'bussy-banditos',
  'buykey-esports',
  'buzzkill-esports',
  'bwc',
  'bx3-esports-club',
  'by-any-means-necessary',
  'byzantine-raiders',
  'c-est-la-vie',
  'c-generation',
  'c0ntact-gaming',
  'c0ntact',
  'c4-base',
  'c4-gaming',
  'cabana-vip',
  'caladrius',
  'calamity',
  'calaoscornos',
  'calmrthanyouare',
  'camel-riders',
  'camlica',
  'camo',
  'campus-vejle-flammen',
  'campus-vejle',
  'canon-event',
  'careperrove',
  'caretos',
  'carnage-esports',
  'caronte',
  'carrion',
  'carte-blanche',
  'cartoonxcrushez',
  'cascadia',
  'case-esports',
  'case-hardy-boyz',
  'case',
  'caseking-gaming',
  'cat-dad-gamers',
  'cataclysm',
  'catchii',
  'category-5',
  'catevil',
  'catpiss',
  'caught-off-guard',
  'cavaniagent',
  'cavehubbers',
  'caz-esports',
  'cb-gaming',
  'cc-oglum',
  'ccg-esports',
  'ceara',
  'cebolos',
  'center-talent',
  'central-fs',
  'cepter-bitskins',
  'cepter',
  'cerberusesports',
  'cex-eps',
  'cex',
  'cg',
  'champagne-gaming',
  'changuitopeek',
  'chaos-esports-club',
  'chaos',
  'chaseisbad',
  'checkmate',
  'checksix-gaming',
  'cheeseltd',
  'chemtrails',
  'cherry-bombs',
  'chetz-esports',
  'chetz',
  'chiefs-esports-club',
  'chiefs',
  'chill-dongas',
  'chillidill',
  'chimpaim',
  'china-gamers',
  'chinanocan',
  'chinese-puggers',
  'chinggis-warriors',
  'chococheck',
  'chokbar',
  'choke-gaming',
  'chosen5',
  'chucklestorm',
  'ciao-scuzzi',
  'cicadas-gaming',
  'cierzo-esports',
  'circuitesc',
  'citim-tanecno',
  'city-boys-up',
  'cityboyz',
  'ck0poct6',
  'clan-mystik',
  'classless',
  'claymakers',
  'cleaningourdesk',
  'cleantmix',
  'clg-red',
  'clickt',
  'climbo',
  'close-society',
  'cloud9',
  'clowncar-legacy',
  'clowncar-unchained',
  'club-brugge',
  'cluckers-inc',
  'clutch-kings',
  'clutch-rayn',
  'clutch-solutions',
  'clutchit',
  'cmd',
  'cnb-e-sports-club',
  'coalesce',
  'coastal-esports',
  'cocacherie',
  'cockroach',
  'codewise-unicorns',
  'cog',
  'cohesion',
  'collateral',
  'colorsgaming',
  'coluant',
  'comautusgg',
  'comedyclub',
  'comet-crew',
  'common-atom',
  'complexity-gaming',
  'complexity',
  'connector-one',
  'conquer-esports',
  'conquer-gaming',
  'conquer',
  'conquergg',
  'conquest',
  'conquistadors',
  'conscious-gaming',
  'contact-light',
  'control-esports',
  'control',
  'convicted',
  'cooked',
  'cool-academy',
  'cool-gaming',
  'cool-juniors',
  'coolermaster',
  'coolnmysterious',
  'coordinated-disrespect',
  'copenhagen-flames',
  'copenhagen-wolves',
  'core',
  'corinthians-academy',
  'corinthians-cds',
  'corinthians',
  'corvidae',
  'coscu-army',
  'cosmoss-riga',
  'costcappen',
  'counter-logic-gaming-red',
  'counter-logic-gaming',
  'counter-nature',
  'counterspec',
  'coupdegrace',
  'courageg',
  'coven',
  'cowana',
  'cph-esport',
  'cplay',
  'cplm',
  'cr4zy',
  'cream-real-betis-sa',
  'cream-real-betis',
  'cream',
  'creative-avez',
  'crep-xtreme',
  'crime-squad',
  'cringe-gods',
  'crispy-noodles',
  'critical-chaos',
  'crossfire-team',
  'crowcrowd',
  'cruzeiro',
  'cryptex-florum',
  'cryptova',
  'cs-kings',
  'cs-nightmares',
  'cs-uw',
  'cs2antitalents',
  'cs2team',
  'csgo',
  'csgo2x',
  'csgoats',
  'csgoatse',
  'csgocasino',
  'csgonads',
  'csta-2',
  'csw-esports',
  'ctrl-alt-defeat',
  'cube-cyber',
  'cult',
  'cum-chasers',
  'cumbup',
  'cunning-ruse',
  'cupava-veverica',
  'curlews',
  'curse-gaming',
  'cxirk',
  'cyaatthemajor',
  'cyber-hero',
  'cyber-legacy',
  'cyber',
  'cybershoke',
  'cyberstorm-blue',
  'cyberstorm',
  'cyberstorn-blue',
  'cyberx-khabarovsk',
  'cyberzen',
  'cyclonegg',
  'cynder',
  'cynical5',
  'd13',
  'd1jar1peek',
  'd2-esports',
  'daddies',
  'daddys-lil-men',
  'dadpeek',
  'dads-that-vape',
  'dadsclub',
  'dakko-team',
  'damage-control',
  'damnstar-esports',
  'dankermemerson',
  'daotsu',
  'dark-matter-esports',
  'dark-passage',
  'dark-sided',
  'dark-tigers',
  'darkest-hour',
  'dat-team',
  'davenport-university',
  'dawg-walkers',
  'dawgz',
  'dayhope',
  'dbl-poney',
  'de-eznuts',
  'de-generates',
  'de-stroyed',
  'dead-in-brain',
  'deadpixels',
  'death-squad',
  'death-trap-e-sports',
  'deepfriedfrenz',
  'defeaters',
  'deftfox',
  'defusekids',
  'defyant',
  'degen-gamblers',
  'degen-woody-cs',
  'delta-syndicate',
  'delta',
  'demise',
  'demolition-crew',
  'demolition',
  'dendd',
  'denial-esports',
  'denied',
  'dennys',
  'depre',
  'depression',
  'depressumhaedos',
  'depth',
  'desolate',
  'desperados-cs2',
  'destruct',
  'detona-gaming',
  'detona',
  'detonate-academy',
  'detonate',
  'detonator',
  'deus-lux',
  'deviance',
  'devils',
  'dewa-united',
  'dgg',
  'dhlg',
  'dial-it-in',
  'dice',
  'diffendo',
  'digital-wave',
  'dignitas-fe',
  'dignitas',
  'diminish',
  'dindinz',
  'dingleberries',
  'dingtone',
  'dinq',
  'dippers',
  'dire-wolves',
  'dirt-gang',
  'dirtbags',
  'dirty-deaglers',
  'dirtyslayers',
  'discocat',
  'district-7',
  'divebar',
  'divina-fe',
  'divina',
  'divine-vendetta',
  'divine',
  'divizon',
  'dn-gaming',
  'dna',
  'dng',
  'dnipro',
  'dnmk',
  'dobryandgaming',
  'doc',
  'dodg3rs',
  'dodge-team',
  'doge-soldiers',
  'dogg-peek',
  'dogmination',
  'dogs-of-war',
  'dogshtters',
  'dogshxtmilitia',
  'dogwater',
  'dom-towarowy',
  'dominance-blue',
  'domino',
  'dominoz',
  'dont-trip',
  'doodlebob',
  'doomfury',
  'doomgg',
  'dot-stats',
  'dotpixels-e',
  'double-cream',
  'double-damage',
  'double-poney',
  'down-to-frag',
  'down2earth',
  'downbadcs',
  'downfall',
  'doxa',
  'dp-bandits',
  'dpg-gaming',
  'dpg',
  'dqzs',
  'dr',
  'dr',
  'draconic',
  'dragons-ec',
  'dream-chaser',
  'dream',
  'dreameaters',
  'dreamscape',
  'dreamworks',
  'dripmen',
  'drippy-lab',
  'dts-gaming',
  'dubnica-academy',
  'duckduckgoose',
  'ducks',
  'dummied',
  'dunderhonung',
  'duoq-ulkohuus',
  'durany-rising',
  'durany',
  'dusty-roots',
  'dusty',
  'dusty',
  'duuhsquad',
  'dxa',
  'dynamo-eclot-thunders',
  'dynamo-eclot',
  'dynasty-na',
  'dynasty',
  'dynastyesc',
  'e-frag',
  'e-galo',
  'e-town-gaming',
  'e-wolves',
  'e9',
  'eagleeyeacademy',
  'ebea2',
  'eber-red',
  'ebro',
  'ec-banga',
  'ec-genk',
  'ec-kyiv',
  'ec-livonia',
  'ecc-kats',
  'echo-fox',
  'eclezya-esport',
  'eclige-reborn',
  'eclipse',
  'eclot-gaming',
  'eclot',
  'eco-warriors',
  'ecogoblins',
  'ecstatic',
  'edemdomoy',
  'edgebugoffsilo',
  'edlt',
  'edward-gaming',
  'eeepy-time-crew',
  'eeriness',
  'efrog',
  'eg-black',
  'eg-gold',
  'eg',
  'eg',
  'eg0ism',
  'egg-clan',
  'ehome',
  'eidos',
  'eitonnepaase',
  'ejax-jaguars',
  'ekasi',
  'eko',
  'el-chombos-fan-club',
  'electrify-steel',
  'electrocuted',
  'electronik-generation',
  'elemental',
  'elements-pro-gaming',
  'elevate-gg',
  'elevate',
  'elfeex',
  'elites',
  'elo-farmers',
  'elothrowers',
  'elusive',
  'emc',
  'emc2-esports',
  'emerald-isle',
  'emeritos-banditos',
  'emerytura',
  'employed',
  'emulate!',
  'en-kerro',
  'ence-academy',
  'ence-athena',
  'ence-esports',
  'ence',
  'encore',
  'endangered',
  'endgame',
  'endless-reign',
  'endpoint-academy',
  'endpoint-cex',
  'endpoint',
  'eneida',
  'enemy',
  'energise-club',
  'energy-esports',
  'energy',
  'energyultra',
  'enervate-gaming',
  'enginebr',
  'eniman-has-kit-in-bold',
  'enjoy',
  'enjoyers',
  'enormity',
  'enosis-esport',
  'enosis',
  'enosiscr',
  'enosiscs',
  'enro-griffins',
  'enso',
  'enterprise-esports',
  'enterprise',
  'entity-gaming',
  'entropiq-future',
  'entropiq-prague',
  'entropiq-queens',
  'entropiq',
  'entropy-future',
  'entropy-gaming',
  'entropy-youth',
  'entropy',
  'envy',
  'envyus-academy',
  'enyoy',
  'enzo',
  'ep-prodigy',
  'epic-dude',
  'epic-dudes',
  'epicdudes',
  'epikk-esports',
  'epiphany-bolt',
  'epochal-esports',
  'epsilon-esports',
  'eq-gaming',
  'eqole',
  'equasion',
  'era',
  'erau-esports',
  'erebus',
  'eros-red',
  'eros',
  'eruption',
  'ervelo',
  'esahara',
  'esc-gaming',
  'esc',
  'esca-gaming',
  'esca',
  'escape-gaming',
  'escape-velocity-esports',
  'escapist',
  'esg',
  'esh-rebels',
  'esimed',
  'espada',
  'esper',
  'espionage-br',
  'espionage',
  'esport-alltech',
  'esport-factory',
  'esport-harte-',
  'esport-harte',
  'esport-rhein-neckar',
  'esports-cologne',
  'esports-gaming-reborn',
  'esports-gloodys',
  'esports4extreme',
  'espuma',
  'esu',
  'esuba-at',
  'esuba-youngsters',
  'esuba',
  'eta',
  'etab',
  'etb-speedrunner',
  'eternal-fire-ac',
  'eternal-fire-academy',
  'eternal-fire',
  'eternal-focus',
  'eternity-gaming',
  'ethereal',
  'etherian',
  'ethos',
  'eunited',
  'euronics-gaming',
  'evicted',
  'evidence-e-sports',
  'evil-corp',
  'evil-geniuses',
  'evil-legion',
  'evil-side',
  'evilnine',
  'evokate-gaming',
  'evolution',
  'evolve',
  'ewave',
  'ewm-gaming',
  'ex-astris',
  'ex-coalesce',
  'ex-enhanced',
  'ex-flames-ascent',
  'ex-gators',
  'ex-mt',
  'exaequo-italia',
  'exalis',
  'exalty',
  'excellency',
  'exceritus',
  'excessiveofaim',
  'exdee-gaming',
  'exdee',
  'exdt',
  'execute-meso',
  'executioners',
  'exen-academy',
  'exen',
  'exertus-esports',
  'exhibit',
  'exloders',
  'exodia-peek',
  'exodian',
  'exodus',
  'exousia-esports',
  'experiment-one',
  'expert-esport',
  'expert-gaming',
  'exploit-esports',
  'exploit',
  'explosive',
  'exsad',
  'exsequor',
  'exsto',
  'extatus',
  'extra-salt',
  'extra-tilt',
  'extremix',
  'extremum',
  'exynt',
  'exzentriq-utd',
  'eye-of-horus',
  'eyeballers',
  'eyesports',
  'ez-cheeks',
  'ezcaladreipo',
  'ezentify',
  'ezpz',
  'ezskins',
  'f4f',
  'fa-white',
  'fab-games-esport',
  'facecal',
  'fact-revolution',
  'factory-esport',
  'fade',
  'faded-squad',
  'fadee',
  'failure-lab',
  'faint-gaming',
  'falcons',
  'falke-academy',
  'falke-ec',
  'falkn',
  'falkol',
  'fallen-legion',
  'fam143',
  'famas-op-on-t-s',
  'familia',
  'family-table',
  'family',
  'familybroken',
  'fantasia',
  'fantastic-seven',
  'faraon',
  'farsa-clan',
  'fate-mena',
  'fate',
  'fathom-gaming',
  'favela-monkas',
  'fawk-archer-guy',
  'faze-clan',
  'faze',
  'fbg',
  'fcz',
  'feelings-wheel',
  'feenix',
  'fefe-fan-club',
  'felt',
  'fenix-team',
  'fenix',
  'ff-when',
  'fidelity',
  'fides-eques',
  'fiend',
  'fierce-esports',
  'fiftyeight',
  'fightclub',
  'filhos-de-d10s',
  'filledcrisps',
  'filthy-casuals',
  'final-feature',
  'final-gaming-speedlink',
  'finest',
  'fire-capes-only',
  'fire-dragoon-e-sports',
  'firegamers',
  'firewolf-clan',
  'first-memory',
  'fistbumpsonlan',
  'fitnessclubcs',
  'five-kings',
  'fivegubbz',
  'flamengo',
  'flash-gaming',
  'flawless',
  'flexibility',
  'flickgame',
  'flipsid3-tactics',
  'flisk',
  'floki-hellheim',
  'flood',
  'floripa-stars',
  'flostate',
  'flow',
  'flowsta',
  'fluffy-aimers',
  'fluffy-gangsters',
  'fluffygangsters',
  'fluxia',
  'fluxo',
  'flying-penguins',
  'flyingh1rsch',
  'flyquest-red',
  'flyquest',
  'fmesports',
  'fn-esports',
  'fnatic-academy',
  'fnatic-rising',
  'fnatic',
  'focal-joint',
  'focal-point',
  'foggy-esports',
  'follow-please',
  'for-the-ages',
  'for-the-win-esports',
  'for-the-win-evolution',
  'for-the-win',
  'forbidden',
  'force-majeure',
  'force-of-nature',
  'forever-youngs',
  'forge',
  'forglory',
  'formidable',
  'forsaken',
  'fortis',
  'fortuna-esports',
  'forward-gaming',
  'forward',
  'forze-yng',
  'forze',
  'fourteen',
  'fourth-quarter',
  'foxed',
  'foxnyr-esports',
  'foxton',
  'frag-executors',
  'fragbox',
  'fragmatic',
  'fragsters',
  'fraternitas',
  'fraternity-',
  'fraternity',
  'fraudsterscs',
  'freaksoflegend',
  'free-ton',
  'free-win-sweep',
  'freealex2k',
  'freelanders',
  'freestyle',
  'french-nation',
  'frenchfrogs',
  'fresh-js-squad',
  'friendlyfire',
  'friendshippower',
  'friendsofbryan',
  'frig-archer-guy',
  'frikitonagaming',
  'frostfire',
  'ftw-ashes',
  'ftw-esports',
  'ftw-madalentejo',
  'ftw',
  'fuark',
  'fuck-helicopters',
  'fudge-packers',
  'fueled-by-hate',
  'fulcrum-esports',
  'fullshock',
  'fullspectrumsqd',
  'funplus-phoenix-blaze',
  'funplus-phoenix',
  'furia-academy',
  'furia-esports',
  'furia-fe',
  'furia-female',
  'furia-gaming',
  'furia',
  'furious-argentina',
  'furious-gaming',
  'furious',
  'fury',
  'furya',
  'fwrd',
  'fylkir',
  'fyr',
  'g2-esports',
  'g2-oya',
  'g2',
  'g3nerationx',
  'g4yhound',
  'gaijin',
  'gaimin-gladiators',
  'galatic-goonz',
  'galaxy-brains',
  'galaxy-gaming',
  'galaxy-racer-fe',
  'galaxy-racer',
  'galaxygaming-lt',
  'galorys',
  'gambit-esports',
  'gambit-gaming',
  'gambit-youngsters',
  'gambit',
  'game-agents',
  'game-fist',
  'game-is-game',
  'game-life-balance',
  'game-pub',
  'game-pulse',
  'gameagents',
  'gamefist',
  'gameover',
  'gameplaydna',
  'gamepub',
  'gamepulse-czech',
  'gamerhands',
  'gamerlegion',
  'gamers-anonnymous',
  'gamers-anonymous-clan',
  'gamers-anonymous',
  'gamers2',
  'gamerzone',
  'games-academy',
  'gametv',
  'gamezone',
  'gamja-namjas',
  'gang',
  'garaza',
  'garazagg',
  'garden-gnomes',
  'gate-keepers',
  'gator-pit',
  'gbcxz',
  'gbh-gang',
  'gbots-esports-club',
  'gdansk-lions',
  'ge-silver',
  'geekz-academy',
  'geekz-de',
  'geekz-energy-de',
  'geekz-energy',
  'geishakuulat',
  'gem-reborn-v3',
  'gemuend',
  'gen',
  'genaaide-boys',
  'generation-y',
  'genetexe',
  'genk',
  'genone-academie',
  'genone',
  'genuine-gaming',
  'genuine',
  'genz',
  'gerber-peek',
  'get-fked',
  'getting-good-v1',
  'geud-gaming',
  'gf-gaming',
  'ggconquer',
  'ggp-clan',
  'ggpr',
  'ggwp',
  'ghost-gaming',
  'ghoulsw',
  'ghr-e-sports-',
  'giants-gaming',
  'giants',
  'giga',
  'gigx',
  'gimmik-pearls',
  'gines-horsemen',
  'girth-kitties',
  'gkc',
  'glichtech',
  'glitchtech',
  'glizzy-gobblers',
  'glock-blockers',
  'glock-gobblers',
  'glockdoctors',
  'glockwork',
  'glore',
  'glorious-esport',
  'gluten-demons',
  'gmt',
  'go-next',
  'goart-on-it',
  'goashax',
  'goats',
  'gobananas',
  'gochu-gobblers',
  'god-complex',
  'god-squad',
  'god-timing',
  'godlike-goats',
  'gods-reign',
  'godsent-academy',
  'godsent-fe',
  'godsent',
  'godzillax',
  'goin-huge',
  'gojos',
  'golden',
  'goliath-gaming',
  'goliath',
  'gonomore',
  'goober-gang',
  'goobgob',
  'good-for-three',
  'good-game-pr',
  'good-game',
  'good-guy-team-2',
  'goodgame',
  'goodjob',
  'goofy-gooberz',
  'google-docs',
  'goons-',
  'gooshedd',
  'goozpeek',
  'gorilla-tactics',
  'gosu',
  'gothboiclique',
  'gourmet-guild',
  'gp-esports',
  'gplay',
  'gr',
  'gr4ndpa-go0nz',
  'grandpa-berets',
  'granit-gaming',
  'granit',
  'granted',
  'grass-touchers',
  'graveyard-peek',
  'graveyard-shift',
  'gravity-0',
  'gravy-train',
  'grayfox',
  'grayhound-gaming',
  'grayhound',
  'great-burritos',
  'greenwhite',
  'gremlenbl',
  'griefers',
  'griffins-es',
  'grilled-chicken',
  'grim-job',
  'grindas',
  'grogu',
  'grond',
  'ground-zero-gaming',
  'ground-zero',
  'grow-up-esports',
  'grow-up',
  'grubhub',
  'gryptionacademy',
  'gtz-bulls-esports',
  'gtz-bulls',
  'gtz',
  'gtz',
  'guangdong-tigers',
  'guangdongtigers',
  'guardian-5',
  'guardian-esport',
  'guardians',
  'gucci-academy',
  'gucci-gaming-ac',
  'gucci-squad',
  'guccisqd',
  'guerillatactic',
  'guild-eagles',
  'guild-fe',
  'guild',
  'gun5',
  'guns-and-rage',
  'guppies',
  'gux-friends',
  'gvc',
  'gwms-gamingclub',
  'gx',
  'h00liganz',
  'h0mele55',
  'h0t-academy',
  'h0t-prodigies',
  'h2k-gaming',
  'h4untedfamily',
  'ha-masse',
  'haastig-ftr',
  'hafo-gang',
  'hail-venum',
  'hakuna',
  'hakunamatata',
  'hall-of-heroes',
  'halla',
  'halow',
  'happy-people',
  'harakternyky',
  'hard-legion',
  'hard-stuck-im',
  'hardcoded',
  'hardestworkersinesports',
  'hardy-boyz',
  'harkers',
  'harte',
  'haspers',
  'hat-tossers',
  'hated-by-most',
  'hatemania',
  'hauntedfamily',
  'havan-liberty-academy',
  'havan-liberty',
  'havik',
  'havu-gaming',
  'havu',
  'hawks',
  'hazard',
  'hde',
  'headcase',
  'headshot-lounge',
  'headshotbg',
  'headshotkings',
  'heatwave-esport',
  'heatwave-tmp',
  'heaven-and-hell',
  'hedgehogs',
  'heet',
  'heimo-esports',
  'heimo',
  'hella-ja-osujat',
  'hellcats',
  'hellraisers',
  'hellreign-ac',
  'hellside',
  'helmet-testers',
  'helmet-wearers',
  'helsinki-reds',
  'here-we-go-agen',
  'heretics',
  'heroc1ty',
  'heroic',
  'heroin-monkeys',
  'hexagons',
  'heyders',
  'hezos-gaming',
  'high-coast',
  'high-five',
  'high-hub-gaming',
  'high-velocity',
  'highq-esports',
  'highrollers',
  'hightrustfactor',
  'highway-robbery',
  'hihowareya',
  'hijiki',
  'hite-gaming',
  'hoes-mad',
  'hog-squad',
  'holkattes',
  'holly-molly',
  'hollywood',
  'holy-trifecta',
  'holy-trifectaac',
  'homies-ot',
  'homixide',
  'homyno',
  'honoris',
  'honved',
  'hoorai',
  'hopium-gaming',
  'horny',
  'horus-esports',
  'hostile-actions',
  'hot-content',
  'hot-flash',
  'hot-headed',
  'hot-prodigies',
  'hotdodgers',
  'hotheadedgaming-academy',
  'hotu-esports',
  'hotu',
  'hound',
  'house-platypus',
  'hreds',
  'hs',
  'hsl',
  'hslow',
  'huat-zai',
  'huilos-gang',
  'hummer',
  'hunters',
  'hydegg',
  'hydrohomies',
  'hyenas',
  'hype-e-sports',
  'hype',
  'hyper5cs',
  'hyperion-',
  'hyperion',
  'hyperspace-dark',
  'hyperx-fr34kshow',
  'hypewrld',
  'hysteria',
  'ia-esports',
  'iam',
  'icewolves',
  'icon',
  'identity',
  'igame-com',
  'igame',
  'ignis',
  'ignite',
  'ihc',
  'ikeapoyta',
  'ikla',
  'ilife',
  'ilin',
  'illegalpassport',
  'illes-akademia-spirit',
  'illes-spirit',
  'illicit-pug-pics',
  'illsint',
  'illuminar-gaming',
  'illuminar',
  'illusion',
  'illustrious',
  'illyrians',
  'imaginary-gaming',
  'imagine-ducks',
  'imba-force',
  'imbadog',
  'imd',
  'immortals',
  'imperial-dragons',
  'imperial-e-sports',
  'imperial',
  'imperishable-ce',
  'imperishable-clan',
  'imperium',
  'imperiumcs',
  'impress',
  'impression',
  'in-bed-by-eight',
  'in',
  'ination',
  'incept',
  'inception',
  'inch-n-a-half',
  'incheon-on-sla2ers',
  'inchk1ng',
  'incontrol',
  'inde-irae',
  'independence',
  'indinda',
  'inevitable-loyalty',
  'infalible',
  'infamous-girls',
  'infamous',
  'infernal-gamerz',
  'inferus-esports',
  'inferus-lt',
  'infidels',
  'infinite',
  'infinity-esports',
  'infinity',
  'inflames',
  'infurity',
  'inglorious-gmg',
  'inglorious',
  'iniquity',
  'innercircle',
  'innov8ors',
  'innovate',
  'inp-ferrata',
  'inp-valeria',
  'inperpetuumequ',
  'inpower',
  'insaneasylum',
  'insanity',
  'insanium',
  'inshock',
  'inside-games',
  'insiders',
  'insilio-betboom',
  'insilio',
  'insomnia',
  'instinct',
  'intact',
  'intech-tenerife-titans',
  'integral-nation',
  'intense-game',
  'intense',
  'interdice',
  'interial',
  'into-the-beach',
  'into-the-breach',
  'into-the-milk',
  'into-the-rim',
  'intothebarrel',
  'intricate',
  'intz-e-sports',
  'intz-esports',
  'intz',
  'inversion',
  'invictum',
  'invictus-aquilas',
  'invictus-gaming',
  'invictus-international',
  'invictus',
  'invie-gaming',
  'inward',
  'ique',
  'ique',
  'irie-legacy',
  'irlcs2',
  'iron-blood',
  'iron-branch',
  'iso',
  'istanbul-wildcats',
  'isurus-gaming',
  'isurus',
  'itaintoveryet',
  'italgo',
  'its-ok',
  'iwku-gaming',
  'iwnl-sweden',
  'iwnl',
  'ixo-esports',
  'izako-boars',
  'j1l-academy',
  'jack3d',
  'jaguar',
  'jaguares-impetus',
  'jaguares',
  'jahsdnmasjdm',
  'jailsmokers',
  'jake-bube',
  'jakepaulfanclub',
  'jano',
  'japaleno',
  'jeleel-juice',
  'jelly-jammers',
  'jerseyboys',
  'jesjakjes',
  'jeste',
  'jft',
  'jijiehao-international',
  'jijiehao',
  'jimmys-m0m',
  'jinkerts',
  'jinseo',
  'jit-trippin',
  'jjangs',
  'joe-bizon',
  'joebidenesports',
  'joep90g0d',
  'join-the-force',
  'jointheforce',
  'jons-au',
  'josh-h-fanclub',
  'joueurs-de-pc',
  'juegalo',
  'juggernauts',
  'juked',
  'just-cause-project',
  'just-enough-salt',
  'justice',
  'jvac-esports',
  'jynkka',
  'k-hold',
  'k0ht0pa',
  'k0ncept-kuwlaka',
  'k10',
  'k1ck-esports-club',
  'k1ck-esports',
  'k1ck-esports',
  'k1ck',
  'k23',
  'kabum!-e-sports',
  'kadlubki',
  'kahoot-squad',
  'kalivan-squad',
  'kanga',
  'kaos',
  'kaoz',
  'kappa-bar',
  'kappahorse',
  'kari',
  'karont3-e-sports-club',
  'katuna',
  'kavenski',
  'keepurselfsafe',
  'kerchnet',
  'keyd-stars-academy',
  'keyd-stars-female',
  'keyd-stars',
  'keyd',
  'kg-network',
  'khan',
  'kiki-the-narc',
  'killbox',
  'killerfish-esport',
  'kin2g',
  'kindergarten',
  'kinetic',
  'kingsmen',
  'kingsofthenorth',
  'kingzzz',
  'kinky-party',
  'kinship',
  'kira-clan',
  'kiritos-disciples',
  'kitchen-experts',
  'kitsune',
  'kitsuneza',
  'kitten-clan',
  'kkkkkkkkkkkkkk',
  'kkona',
  'klaksvik',
  'klanrur',
  'klikexe',
  'kodak-moment',
  'kodatts-kool-kids',
  'kodeone',
  'kogutos',
  'koi',
  'koksik',
  'kolon3',
  'kolossus',
  'kon-finland',
  'kon-norway',
  'kon-sweden',
  'kontencja',
  'koomerz',
  'kopatic',
  'kord',
  'kordrengir',
  'kova-esports',
  'kova-gaming',
  'kova',
  'koz-getir',
  'kozmosisesports',
  'kpi-gaming',
  'kpi',
  'kpv',
  'kpw',
  'kraken',
  'krc-genk-esports',
  'krc-genk',
  'krewhl',
  'krisp',
  'kron-klan',
  'kron-talent',
  'kronjylland-main',
  'kronjylland',
  'krutki-lont',
  'kstraters',
  'kt',
  'ktrl-x-udp',
  'ktrl',
  'kubix-gaming',
  'kuikuilijat',
  'kuuhaku',
  'kuusamo',
  'kuusamogg',
  'kv-mechelen',
  'kviymictokbitib',
  'kyiv',
  'kyivmictokbitib',
  'kynyniekat',
  'kzg',
  'l00kingf0r0rg',
  'l00ped-esports',
  'l2w-esports',
  'la-lurkers',
  'lag',
  'laittikola',
  'lajtbitexe',
  'laminatedvcards',
  'landodgers',
  'landon-esports',
  'langamers',
  'lanky-smoove',
  'lapatka',
  'lasgana-boys',
  'lassos',
  'last-dance',
  'last-minute',
  'lastchance',
  'lastdance',
  'lastwind',
  'latifisupremacy',
  'latincowboys',
  'lattenhagen-dso',
  'lausanne-se',
  'lausanne',
  'lazarus-esports',
  'lazarus',
  'lazercats',
  'lbs',
  'ldlc-ol',
  'ldlc',
  'ldlc',
  'ldlc',
  'left-lean-gang',
  'leftout',
  'legacy-esports',
  'legacy-of-aeons',
  'legacy',
  'legopeek',
  'leisure',
  'lemondogs',
  'lenovo-legion-honved',
  'lenzgeneration',
  'leon',
  'lerums-yngre',
  'les-bons-riens',
  'les-bowlcuts',
  'les-sales-babz',
  'lese-esports',
  'lese',
  'let-s-quit',
  'lethal-divide',
  'lethaldividegg',
  'lets-get-lively',
  'levadia',
  'level-5-rats',
  'level1heroes',
  'leviatan-academy',
  'leviatan',
  'levitate',
  'lf0',
  'lfo',
  'lgb-esports',
  'liberty-fe',
  'liberty',
  'lickin-my-feet',
  'lifted',
  'lighting-bolts',
  'lilmix',
  'lilmixgg',
  'limitless',
  'lindenwood-cs',
  'line5-academy',
  'line5',
  'linx-legacy-vendetta',
  'linx-legacy',
  'linxlegacy-mdgr',
  'liquid-biceps',
  'liquid',
  'livid',
  'll-devil',
  'll-madagaskar',
  'llimajlb-gaming',
  'lmaograndaddies',
  'lng',
  'loading',
  'lobotomy-gaming',
  'local-c9',
  'locastic',
  'lockheeddynamic',
  'loco-smoco',
  'lodis-by-illuminar',
  'lodis',
  'lofi-hip-hop-radio',
  'logic-gaming',
  'logix-e',
  'lokomelo',
  'london-conspiracy',
  'london-esports',
  'london-lynx',
  'lonely-fans',
  'lookinfororg',
  'looking-for-org',
  'looking4beer',
  'lookingforawper',
  'lookingfororg',
  'loot-goblins',
  'los-3cm',
  'los-del-ts',
  'los-grandes-academy',
  'los-grandes',
  'los-kogutos',
  'los-pistoleros',
  'los',
  'lost-esports',
  'losthills',
  'lostik',
  'lotb',
  'loto',
  'lotus',
  'lounge-gaming',
  'lowbobs',
  'lowkey-pro',
  'lowlandlions',
  'lps-esports',
  'lpsp',
  'lrv-esports',
  'lrv',
  'lsc',
  'ltd-map-pool',
  'lucent',
  'lucid-dream',
  'luciddream',
  'lumenvitae',
  'lumina-gaming',
  'luminosity-gaming',
  'lumos',
  'lung-punchers',
  'luriluri',
  'lushi',
  'luura',
  'luxe',
  'lv-crocs',
  'lyngby-vikings',
  'lynn-vision',
  'lz',
  'm0nster',
  'm1-eden',
  'm1-team',
  'm1',
  'm16',
  'm19',
  'm4deyesgamingac',
  'm80',
  'm81',
  'macherbande',
  'mad-kings',
  'mad-like-wizards',
  'mad-lions',
  'made-in-boston',
  'made-in-denmark',
  'made-in-thailand',
  'madjick-gaming',
  'madlikewizards',
  'madvillainy',
  'maelstrom',
  'maestro',
  'mafia',
  'mafu-fan-club',
  'mag',
  'maggots',
  'magic',
  'magical-eu',
  'magiclight',
  'magistra',
  'magnats',
  'main-auto-parts',
  'main-gamers',
  'majestic-lions',
  'majesty',
  'make-your-destiny',
  'makeshift',
  'makia',
  'maknitude',
  'mako',
  'malicious-int',
  'malvinas',
  'manageyself',
  'manatee-esports',
  'mandragora',
  'maniacs-executors',
  'mansionag',
  'mansland',
  'maquinas',
  'mariners',
  'mark-squad',
  'marlian',
  'marrecos-rising',
  'marrecosgg',
  'marrymescratte',
  'marskalk',
  'marsonsonlyfans',
  'marsupialgaming',
  'marten-rising',
  'marten',
  'martians',
  'masked',
  'masonic-academy',
  'masonic',
  'massive-ocean',
  'master-club',
  'mastermind',
  'mastermix123',
  'matt-arend-g-a',
  'matusmatyssimko',
  'mavky',
  'maxandrelax',
  'maxfloplay',
  'maxima',
  'maxisaucisse',
  'mayam-gaming',
  'maybeprob',
  'maycam-evolve',
  'mazaalai',
  'mb',
  'mc-e-sports',
  'mc',
  'mcon',
  'mcs-gaming',
  'mdauj',
  'meap',
  'medhelmmilitia',
  'mediagruppen',
  'medieval-riga',
  'medieval',
  'medivate',
  'medusa-',
  'meet-your-makers',
  'meetyourdestiny',
  'megahoyla',
  'megazord',
  'meine-arbeit',
  'melac-cyberhack',
  'melty-esport-club',
  'memo',
  'menace-gg',
  'mentalclear',
  'mentally-ill',
  'mentalmidgets',
  'mercury',
  'meta-br',
  'meta-brasil',
  'meta-esports',
  'meta-gaming',
  'meta',
  'meta4pro-jr',
  'meta4pro',
  'method',
  'method2madness',
  'metins-mangos',
  'metizport-x',
  'metizport',
  'mh-elite',
  'miami-flamingos-e-sports',
  'miami-flamingos',
  'miami-pitingos',
  'mibr-academy',
  'mibr-fe',
  'mibr-female',
  'mibr',
  'michael-blackson-uni',
  'michigan-state',
  'michigan-tech-huskies',
  'middle-age-men',
  'midgard',
  'mielisairaala',
  'might',
  'mightygang',
  'mikesmoneykrew',
  'milk-men',
  'millenium',
  'millennium-ae',
  'millennium-owls',
  'millennium',
  'mind-games',
  'mindfreak',
  'mindset-diff',
  'mindtrip',
  'mineski',
  'mirai',
  'miraigaming',
  'mirrored',
  'misfits-gaming',
  'misfits',
  'mishmash-gaming',
  'missing',
  'mississota-gang',
  'mix-machine',
  'mix-n623',
  'mixaajat',
  'mixfits-',
  'mj-peek',
  'mk3-e-sports',
  'mlg',
  'mna',
  'mo-state-bears',
  'mob',
  'mock-cunchers',
  'mode-weed',
  'modeame',
  'modvind',
  'mogfia',
  'mojito-heaven',
  'moke-crethe',
  'moldavian-tiger',
  'molotov-gaming',
  'mommysports',
  'monarch',
  'monaspa',
  'money-gang',
  'money-sexual',
  'moniseum-sub',
  'moniseum',
  'monke-push',
  'monkey5-ac',
  'monolith-gaming',
  'monomaniac-esports',
  'montarzufka',
  'monte-gen',
  'monte-rising',
  'monte',
  'montecarlo',
  'monumentalis',
  'moon',
  'moonday-games',
  'moops',
  'moosine',
  'mopped-up',
  'moralezz',
  'morfanclub',
  'morning-light',
  'moron-5',
  'mortal-kombat',
  'mortality-esports',
  'mortarcs',
  'moscow-five',
  'mossad-agents',
  'mostviertelpeek',
  'mouse-accelerators',
  'mouse4aimers',
  'mousespaz',
  'mousesports',
  'mouz-nxt',
  'mouz',
  'moviestar-riders',
  'movistar-riders',
  'mpoze',
  'mst',
  'mtp',
  'mtw',
  'mudmen',
  'muffin-miners',
  'muhlige',
  'muhlut',
  'multigraindeath',
  'multivitamin',
  'munanyo',
  'mungy-inc',
  'munich-esports',
  'mushyroom',
  'mustang-crew',
  'mustard-tigers',
  'muted-players',
  'muzhiki',
  'mvd-esports',
  'mvp-cyber',
  'mvp-iceland',
  'mvp-karnal',
  'mvp-pk',
  'mvp-project',
  'mvp-space',
  'mvp',
  'mxtm',
  'my-life-be-like',
  'mybad',
  'mydgb',
  'mygame',
  'myinsanity-swiss',
  'myinsanity',
  'myllyn-paras',
  'myllysuola',
  'mynec',
  'myth-e-sports',
  'mythic',
  'mythicc',
  'myxmg',
  'mzp',
  'n!faculty',
  'n-w-g',
  'n-z-w-a',
  'n00rg-cs',
  'n0mads',
  'n1ght-esports',
  'n20',
  'n2e',
  'n3far1ous',
  'na-fascie',
  'na-last-hope',
  'nade-brigade',
  'nameless',
  'nandian-nation',
  'nani-e-sports',
  'nao-tem-como',
  'napt',
  'narcis',
  'narukami',
  'nas-best-bots',
  'nas-last-hope',
  'nashville-peek',
  'nasr-esports',
  'nasr',
  'nasty',
  'nastymittens',
  'natesmile',
  'native-2-empire',
  'natus-vincere-junior',
  'natus-vincere',
  'navi-javelins',
  'navi-jr',
  'navi-junior',
  'navi-youth',
  'navi',
  'navi',
  'nazar-eye',
  'ncsu',
  'nctdk',
  'ndawoods',
  'nebula',
  'necromancer',
  'neds',
  'needone-agency',
  'nekfat-tactics',
  'nemiga-gaming',
  'nemiga',
  'neophyte',
  'neptune-esports',
  'neptune',
  'nerdrage',
  'netcode-illuminati',
  'netcodeguides',
  'netcodeguidesdotcom',
  'neu-gold',
  'neurotic-gold',
  'never-hate',
  'never5-esport',
  'neverest',
  'nevermind',
  'neverplay',
  'new-england-whalers',
  'new-happy',
  'new-pampas',
  'new-quali-team',
  'new-threat',
  'new4',
  'neway',
  'newbelarus',
  'newera',
  'newgen-esports',
  'newgeneration',
  'newhappy',
  'newport-factory',
  'newps',
  'nexga',
  'next-in-line-esports',
  'next-up',
  'nextg',
  'nextplease!',
  'nexus-academy',
  'nexus-gamers-pub',
  'nexus-gaming',
  'nexus',
  'nezumi',
  'ng',
  'nibble-haze',
  'nicecactus',
  'nightmare-esports',
  'nigma-galaxy-fe',
  'nigma-galaxy',
  'nihilum-gaming',
  'niisesports',
  'nikakix',
  'nineshapes',
  'ninguem-ta-puro',
  'ninja',
  'ninjabreadz',
  'ninjas-in-pyjamas',
  'nip-impact',
  'nip',
  'niskalaukaus',
  'nixuh',
  'nixuhgg',
  'njl0thblu-kek',
  'nkt',
  'nku-gold',
  'no-chot',
  'no-face-no-case',
  'no-idea',
  'no-kit',
  'no-limit-gaming',
  'no-limit',
  'no-name',
  'no-namers',
  'no-problem!',
  'no-problem',
  'no-quarter-cartel',
  'no-respect',
  'no-shot',
  'no-sir!',
  'no-tenemos-nada',
  'no7eam',
  'noa',
  'noble-esports',
  'noble',
  'nobo',
  'nobraindrypeek',
  'noc-kruka',
  'nocco-dusty',
  'nochance',
  'noctis-bellator',
  'nocturns',
  'nofear5',
  'nogym-esports',
  'nokevlarreq',
  'nolimitskill',
  'nom-esports',
  'nom',
  'nomatter-',
  'nomral',
  'nonaimers',
  'noname',
  'nonamers',
  'nonameteam',
  'nonnih',
  'nopullouts',
  'nordavind-talent',
  'nordavind',
  'norden',
  'nordix-esport',
  'norsera',
  'north-academy',
  'north-royal',
  'north',
  'northern-forces',
  'northern-legacy',
  'northern-lights',
  'northern-star',
  'northwest',
  'noscape',
  'nosens',
  'nosurrendergg',
  'not-my-entry',
  'not-my-igl',
  'notachance',
  'noteg1',
  'notg4yiswear',
  'nouns-fe',
  'nouns',
  'nova-aetas',
  'nova-gaming',
  'novum',
  'noxa-gaming',
  'noxious',
  'nrg-esports',
  'nrg',
  'nsjj-reborn',
  'nsn',
  'nspr-gaming',
  'ntox',
  'nubile-gamers',
  'nubo',
  'nut-academy',
  'nut-e',
  'nutorious-skewl',
  'nutorious',
  'nutriaesports',
  'nvdd-em-breve',
  'nyyrikki-esports',
  'nyyrikki',
  'o-plano-',
  'o-plano-b',
  'o-plano',
  'o2-peek',
  'ocb-gg',
  'ocbgg',
  'ocean-fire',
  'oceanus',
  'oddik',
  'odium',
  'odra-wodzislaw',
  'odyssey-gaming',
  'off-balance-gaming',
  'off-the-air',
  'off-the-grid',
  'officers',
  'offset-esports',
  'offset',
  'offspring',
  'og-academy',
  'og-hhe',
  'og',
  'oghood',
  'oh-gaming',
  'oh-yeah-gaming',
  'oilers-esport',
  'oilers',
  'ok2strong',
  'okt',
  'old-dogs',
  'old-guys-club',
  'old-kings',
  'old-man-gang',
  'old-mofos',
  'oldfriendsclub',
  'oldnotobsolete',
  'oleron-gaming',
  'olgodefterskole',
  'omega',
  'ominous-esports',
  'omni5',
  'omnius',
  'on-the-money',
  'onamo',
  'once-divine',
  'ondskan',
  'one-last-ride',
  'one-one-esport',
  'one-tap',
  'one',
  'onet4p',
  'onetap',
  'onethree',
  'onlinebots',
  'onliners',
  'onlyfans-v2',
  'onlyfans',
  'onlyfrags',
  'onlytoms',
  'onyx-dojo',
  'onyx-ravens',
  'onyx-talents',
  'onyx',
  'oof-reborn',
  'ooga-booga-es',
  'opaa',
  'opengamers',
  'opera-gx-phantoms',
  'operation-doom',
  'operation-kino',
  'opium',
  'oplano-academy',
  'opp-goblins',
  'optic-gaming',
  'optic-india',
  'opyt',
  'oracle-esport',
  'orange-esports',
  'orbital-vsat-online',
  'order',
  'orgless-5',
  'orgless',
  'orion-oldsters',
  'orion',
  'orionyoungsters',
  'orlando-outlaws',
  'oro-gg',
  'oro',
  'os-catioros',
  'osadia',
  'osama-bin-fraggin-reborn',
  'osama-gaming',
  'oslo-lions',
  'otg',
  'outlast-black',
  'outlast',
  'outplayed',
  'outsiders',
  'ovacion-esports',
  'overfrag',
  'overgaming',
  'overperformers',
  'overrated',
  'overt',
  'ovg-rising',
  'ovg',
  'ovoxo',
  'owl',
  'oxalyde',
  'oxuji',
  'oxygene',
  'p0db0tz',
  'pablow-escobar',
  'pacama',
  'pacos-tacos-2',
  'pact',
  'padaemholodnie',
  'pain-ac',
  'pain-academy',
  'pain-gaming',
  'pain',
  'pais-solteiros',
  'paloma',
  'panda-gaming',
  'pandaric-aces',
  'pandaricesports',
  'panica-gaming',
  'panicattheveto',
  'panter-esports',
  'panthers-gaming',
  'panthers-never-surrend',
  'panthers-never-surrender',
  'panthers',
  'pantsu',
  'papa-bust',
  'paperclip',
  'paperplanes',
  'paqueta',
  'parabellum-fe',
  'paradox-gaming',
  'paradox',
  'parallax',
  'paranoia-esports',
  'parivision',
  'partizan-junior',
  'partizan-red',
  'partizan',
  'partizanesports',
  'parttime-ninjas',
  'party-astronauts',
  'party-daddler',
  'party-daddlers',
  'partydaddlers',
  'pasha-camp',
  'passion-ua',
  'passions',
  'past-our-prime',
  'patatipatata',
  'patinc-patlanc',
  'patins-da-ferrari',
  'pats-money-crew',
  'paw-patrol',
  'pawermoob',
  'pc419',
  'pcific-espor',
  'pcs',
  'pdhm',
  'peace-ducky4',
  'peacegaming',
  'peak',
  'pee-shooters',
  'peeker-s-advantage',
  'peekers-advantage',
  'pel0nalities',
  'penazoid',
  'penchod-peakers',
  'penguin-logis',
  'pensionatet',
  'penta-infusion',
  'penta-sports',
  'penta',
  'pentagram-g-shock',
  'peons',
  'pepeepa-peek',
  'pera',
  'permitta-academy',
  'permitta',
  'peronus-longus',
  'perplexed',
  'pesadilla',
  'pewpew-vikings',
  'pge-turow-zgorzelec',
  'pge-turow',
  'ph-enthusiasts',
  'phantom',
  'phase3',
  'phil-collins',
  'philadelphia-liberty',
  'phoenix',
  'photographers',
  'phyre-gaming',
  'physics-fanclub',
  'pichau',
  'pick-and-split',
  'pigeons',
  'pigsty',
  'pikku-pupu',
  'pirates-esports',
  'piss-gem-gamin',
  'pitchu-gang',
  'pitsuytvosem',
  'pitt-haircuts',
  'pivot',
  'pixelfire-gaming',
  'pixlip',
  'pl123',
  'placeduvillage',
  'placeholder',
  'plague',
  'plan-b',
  'planet-express',
  'planetkey-dynamics',
  'planetoftheapes',
  'planetofthegape',
  'plasma-vitaplur-gum',
  'plasma-vitaplur',
  'platinium-esport',
  'platoon-alpha',
  'platoon',
  'playart',
  'players',
  'playing-ducks',
  'pleingaz',
  'plink-tech',
  'plmix',
  'plnetkey-dynamics',
  'plusw',
  'pmperfect',
  'pnda-gaming',
  'pneuma',
  'pocomaxa',
  'podstol-gaming',
  'poggerz',
  'poggies',
  'pokemon',
  'poker',
  'polar-ace-esports',
  'polar-ace',
  'polet',
  'poligon',
  'polish-cuddlers',
  'polska-gurom',
  'pome-jazda',
  'pomme-do-aca',
  'pompa-team',
  'pompa',
  'por',
  'portal-esport',
  'poseidon',
  'pot-bellies',
  'potential-to-stack-dough',
  'poundland',
  'povydlo-esports',
  'power-clickers',
  'power-gaming',
  'powermode',
  'pozdrowienia-dla-piwa',
  'pr-01',
  'pr-stars',
  'pr1med-esports',
  'preasy-rising',
  'preasy',
  'predvlotl',
  'press-mouse1',
  'press-z',
  'prestige',
  'prevail-anz',
  'pride-gaming',
  'pride',
  'pries',
  'prifu',
  'prima-esport',
  'prima-t',
  'prima',
  'primal-is-fat',
  'prime-delivery',
  'princesses',
  'priority-black',
  'priority-red',
  'priority',
  'prizepicks-employees',
  'prizivanet',
  'prizyvanet',
  'pro-by-40',
  'pro-lapse',
  'pro-nounz',
  'pro-state-kings',
  'pro100',
  'problosingsleep',
  'procyon',
  'prodigiessweden',
  'prodigy',
  'progaming-esports',
  'progaming',
  'proj-hail-mary',
  'project-1',
  'project-24',
  'project-eversio',
  'project-susanoo',
  'project-west',
  'project-x',
  'project8',
  'projectfidelity',
  'projectless',
  'projectx',
  'property',
  'prospects',
  'provality',
  'prowince',
  'proximity-uk',
  'prs-gaming',
  'pryde',
  'pstream-pirates',
  'pte-peac',
  'pts-e-sports',
  'public-enemy',
  'publiclir',
  'pucks-deep',
  'pufflepals',
  'puggin4fun',
  'pugiles',
  'pugstars',
  'pulse-gaming',
  'pureskillggblu',
  'puscasii-marini',
  'pushin-pweice',
  'pv-rap-g0dzzz',
  'pvntsu',
  'pvo',
  'pyypaisti',
  'qlash',
  'qmistry',
  'qu-de-ma',
  'qual4-lfo',
  'quantic-gaming',
  'quantum-bellator-fire',
  'quantum-flames',
  'quantum-prodigy',
  'quantumsyngergy',
  'quazar-nsk',
  'quazar',
  'quazar',
  'quazarplayers',
  'queefers',
  'queso',
  'quest',
  'questionmark-',
  'questionmark',
  'quick-munch',
  'quickplay-grey',
  'quiet-down',
  'quinque',
  'qvg-academy',
  'qvg-esports',
  'r3action',
  'r3m3mb3r',
  'r4g-ruby',
  'r6-to-cs',
  'racing',
  'rad140-abusers',
  'radix-esports',
  'radix',
  'rage-quit',
  'raggie-aimers',
  'raiders',
  'raijin',
  'randys-waifus',
  'rapidunity5',
  'raptors-ec',
  'rare-atom',
  'raslattgaming',
  'rat-pack',
  'ratel-squadron',
  'rattlesnakes',
  'ravens',
  'razed',
  'rbg-esports',
  'rbg',
  'rctic-esports',
  'real-winner-company',
  'realwunz',
  'reason-gaming',
  'reason-gaming',
  'rebels',
  'rebirth',
  'recca-esports',
  'reckoning',
  'recognised-talent',
  'recon-5-black',
  'recon-5',
  'rectify',
  'recursive-esports',
  'red-canids',
  'red-flaggg',
  'red-instinct',
  'red-reserve',
  'red',
  'redacted',
  'redemption-esports-poa',
  'redemption-esports-porto-alegre',
  'redemption-poa',
  'redfear',
  'redgg',
  'redhood',
  'redline-esports',
  'redpack-quantum',
  'redpack',
  'redragen',
  'redragon-academy',
  'redragon-fe',
  'redragon',
  'redux',
  'reece',
  'reform',
  'reformed-gamers',
  'rehl',
  'reign',
  'rejected-by-all',
  'rejectsv2',
  'rejexesports',
  'remorselesswntr',
  'renegades',
  'renewal',
  'rent-is-due',
  'res-gaming',
  'reserve',
  'resilient',
  'resolute',
  'resurgence',
  'resurgencecsgo',
  'retired-aimers',
  'retired-demons',
  'retired5',
  'retirementhome',
  'retrograde',
  'retrowv-academy',
  'reunion',
  'reveal-academy',
  'reveal',
  'revel',
  'revelations-cs',
  'revelationscs',
  'revenge-nation',
  'revival-gaming',
  'revolt-w-united',
  'revolution',
  'rewound',
  'rezist',
  'rgw-esports',
  'rhein-neckar',
  'rhyno-esports',
  'rhyno-youngsters',
  'rhyno',
  'rice-nation',
  'ricochet',
  'riot-squad',
  'ripnstick',
  'riptide-ogs',
  'rise-nation',
  'rise',
  'risin',
  'risky-business',
  'risky-gaming',
  'river-plate',
  'river',
  'rizon',
  'rizondotgg',
  'roa-main',
  'road-to-gold',
  'roar-esports',
  'rocket',
  'roflnation',
  'rogue',
  'rogysanimeteam',
  'rokiskio-elitas',
  'rollout-esports',
  'ronin',
  'room-service',
  'rooster',
  'roots',
  'rosomaha',
  'rot',
  'rotacak',
  'roundsgg',
  'roundsunity',
  'royal-bandits',
  'royal-republic',
  'royalerrambo',
  'royals-uk',
  'royals',
  'royalty',
  'rtsports',
  'rtu-mirea',
  'rude',
  'rufus',
  'rugratz',
  'run-or-die',
  'runaway',
  'rush-b',
  'rush',
  'rush3d',
  'rvg-blacktrains',
  'rvns',
  'rysix-gaming',
  's-a-c-k-e-r-s',
  's-a-d-b-o-y-s',
  's-gaming',
  's2v',
  's3xtremum',
  'sabotage',
  'sacramento',
  'sad-genius',
  'saga',
  'sahangiller',
  'saints',
  'sajanex-esports',
  'sakana123',
  'salamander',
  'salem-catfish',
  'saltcrew',
  'same-5-guys',
  'sampi-next',
  'sampi-tipsport',
  'sampi',
  'samurai-legends',
  'san-marino-team',
  'sangal',
  'sanguine',
  'santos-dexterity',
  'santos-e-sports',
  'santos',
  'sao-caetano',
  'sapper-academy',
  'sashi-academy',
  'sashi-esport',
  'sashi',
  'savage',
  'save-s',
  'savi-esports',
  'saw-youngsters',
  'saw',
  'sawo',
  'sb-gaming',
  'sc-e-sports',
  'sc-esports',
  'scarybears',
  'scbh',
  'scrimless',
  'scuba',
  'sd-invicta',
  'sdu',
  'se7en-esports',
  'seafood-delight',
  'sealions',
  'secomportaya',
  'secondcs2',
  'secret',
  'sector-one',
  'seirin',
  'seize',
  'seizures-palace',
  'selecadobt',
  'selectah',
  'selfish-pricks',
  'selfless-gaming',
  'semper-fidelis',
  'semxorah',
  'sen-mouz',
  'sensei-la-dews',
  'senshi-esports',
  'sensoria-esport',
  'sentora-saints',
  'sentora',
  'senz-esports',
  'sera-esportal',
  'sera',
  'serenity-ec',
  'serenity-gg',
  'serenityesports',
  'serpent-gaming',
  'sestri',
  'seven-wars-e-sports',
  'seven',
  'sevenizi',
  'severe',
  'sfd-academy',
  'sfto-esports',
  'sg-e-sports',
  'sg',
  'sgpro-paradise',
  'sh1zieepeek',
  'shadow-wizard-money-gang',
  'shaolin-esports',
  'shape',
  'shapito-',
  'sharks-esports',
  'sharks-yngs',
  'sharks',
  'shed',
  'shikubi',
  'shimmer-',
  'shimmer',
  'shinzoabefc',
  'shipachi',
  'shiznays',
  'shpl',
  'shreeesports',
  'shtraus',
  'shwiftyy',
  'si9dan',
  'signature-gaming',
  'signatus',
  'sikaniska',
  'silencers',
  'silentgaming',
  'sillycs',
  'silvercrows',
  'simmer-ume',
  'simplicity',
  'sin-muerto',
  'singularity-esports',
  'singularity-female',
  'singularity',
  'sinister',
  'sinister5',
  'sinkin-ship',
  'sinners-academy',
  'sinners-esports',
  'sinners-gaming',
  'sinners',
  'sinsi-atak',
  'sissi-state-punks',
  'sistersonsalary',
  'sixbanners',
  'sj-gaming',
  'sj',
  'sk-gaming',
  'skade-x',
  'skade',
  'skdc',
  'skeptical',
  'skillstars-pro',
  'skinchies',
  'skitlite-gaming',
  'skitlite',
  'skmc',
  'skuak',
  'skull-cracker',
  'skulls-crew',
  'sky-wanderer',
  'skyblades',
  'skyfire',
  'slashlevel',
  'slay',
  'sleepless-gaming',
  'sleepy-shooters',
  'slice-a-ham',
  'slick',
  'slime-gang',
  'slnt-procloud',
  'slnt',
  'sloppy-floppy',
  'slp',
  'smart-faqs',
  'smartcarpeek',
  'smash',
  'smile-and-wave',
  'smiles',
  'smiss-b-team',
  'smiss',
  'smoke-fe',
  'smooth-brains',
  'smuuttikusilkki',
  'snack-club',
  'snake-eyes',
  'snakes-den',
  'snogard-dragons',
  'snowpards',
  'snowpardsviolet',
  'snussyesports',
  'soar-gaming',
  'soarin-n-fourin',
  'soberano-team',
  'socialangstuwu',
  'softcarefoam',
  'softshaft',
  'sojoga-academy',
  'sojoga',
  'sojuu',
  'solaris-esports',
  'solarity-esports',
  'solid-ice',
  'solid',
  'solove',
  'something-funny',
  'something',
  'son-northwinds',
  'sons-of-lennart',
  'sonylandiacones',
  'sorby-flames',
  'sotano',
  'sotanogamer',
  'souldazz',
  'soulfrost-ac',
  'soulfrost-esports',
  'souls-land',
  'sourdoughbros',
  'south',
  'southern-blend',
  'southern-scam',
  'sovereign',
  'soyboysxp',
  'sp1ria',
  'sp8-',
  'space-jam',
  'space-monkeys',
  'space-soldiers',
  'space-station',
  'space',
  'spaced-0ut',
  'spacestation-gaming',
  'spade-gaming',
  'spanner5',
  'spartans-eu',
  'sparx',
  'spate-esports',
  'spaztic',
  'spec-eds',
  'specter-tavius',
  'spectre',
  'speedhun72rz',
  'speedlink',
  'spicy-bois',
  'spicyerbois',
  'spirit-academy',
  'spirit-fe',
  'spirit-female',
  'spirit-of-amiga',
  'spirit',
  'splyce',
  'sponsored-by',
  'spooky-squad',
  'spot-up',
  'sprawgs',
  'sprout',
  'sqkte-hai',
  'squad',
  'square-feet',
  'squared-esports',
  'squared-prospects',
  'squeegee',
  'srplgang',
  'st-cote-de-porc',
  'stabaek-momentum',
  'standing-dudeside',
  'standonbusiness',
  'star-childs',
  'stargazinge',
  'stars-horizon',
  'stars-of-europe',
  'station-7',
  'staymadhoes',
  'steel-helmet',
  'stellar',
  'stillmain',
  'stinc-team',
  'stmn',
  'stompgods',
  'stone',
  'stoneck',
  'storm-rider',
  'storm-wolves',
  'storms-bait-shop',
  'stormuzb',
  'stormzz-',
  'straight-gas',
  'strawhats',
  'strife',
  'strive-in-chaos',
  'stunted-and-old',
  'stuzoo',
  'subutai',
  'subway-surfer',
  'subzero-esports',
  'succ-n-fucc',
  'sugmaidry',
  'suicide-squids',
  'sumakthesenuts',
  'summa',
  'summit',
  'sunburn',
  'sunshineliquors',
  'superjymy',
  'supernova-cs',
  'supernova',
  'supertroll',
  'supremacy',
  'surfers',
  'surge-esports-club',
  'surprisev2',
  'surreal',
  'sussy-reloaded',
  'suxen',
  'swamp-monsters',
  'swampass',
  'swedesnsandhals',
  'sweet-angels',
  'swiftgamers',
  'swing-or-be-swung',
  'swing9',
  'swole-patrol',
  'sws-gaming',
  'sws',
  'syf-gaming',
  'syfgaming',
  'sylyx',
  'syman-gaming',
  'syman',
  'symbiosis',
  'synapse',
  'synck',
  'synergy',
  'synrgy-gaming',
  'syntagma',
  'synthetic',
  'sz-absolute',
  'szeregowcy',
  't-torturers',
  'taboo',
  'tac-titans',
  'tactical-8',
  'tactical-titans',
  'tactical-trolls',
  'tag',
  'tainted-minds',
  'taipancsgo',
  'tajikistanseals',
  'taka',
  'take-aim',
  'take-flyte-a',
  'take-flyte',
  'talanta',
  'tandoor',
  'tarczynski-protein',
  'taubate-esports',
  'tavern-brawlers',
  'taxidermists',
  'tba-benelux',
  'tcm-gaming',
  'tea-party',
  'team-3d',
  'team-7am-na',
  'team-aaa',
  'team-acer',
  'team-adsfk',
  'team-agg',
  'team-alexa',
  'team-alientech',
  'team-alientech',
  'team-ancient',
  'team-ano',
  'team-artistic',
  'team-ascend',
  'team-avenge',
  'team-aztec',
  'team-backpack',
  'team-bald',
  'team-blackout',
  'team-brute',
  'team-catch-gamer',
  'team-coast',
  'team-coop',
  'team-crazycoco',
  'team-cursive',
  'team-dainyko',
  'team-deathgrip',
  'team-denied',
  'team-descend',
  'team-dignitas-female',
  'team-dignitas',
  'team-disrupt',
  'team-diversity',
  'team-dynamic',
  'team-eastpak',
  'team-ebettle',
  'team-elixir',
  'team-empire',
  'team-emprox',
  'team-endpoint',
  'team-envy',
  'team-envyus',
  'team-eu4ia',
  'team-exile5',
  'team-exploited',
  'team-extensive!',
  'team-factor',
  'team-fish',
  'team-forge',
  'team-fragster',
  'team-gamerdesk',
  'team-gamerlegion',
  'team-gaminate',
  'team-gogeto',
  'team-hans',
  'team-heretics',
  'team-hyper',
  'team-ibuypower',
  'team-immunity',
  'team-imperio-multimedia',
  'team-infernum',
  'team-infused',
  'team-innova',
  'team-invictum',
  'team-k-a-r-m-a',
  'team-kinguin',
  'team-ldlc',
  'team-ldlc',
  'team-ldlccom',
  'team-leisure',
  'team-lfrit',
  'team-lioncast',
  'team-liquid',
  'team-lowki',
  'team-lrp-poland',
  'team-m33',
  'team-maple',
  'team-max',
  'team-meliora',
  'team-menace',
  'team-method',
  'team-mischievous',
  'team-monarchs',
  'team-moon',
  'team-mutiny',
  'team-noir',
  'team-nxl',
  'team-odyssey',
  'team-one',
  'team-orbit',
  'team-owl',
  'team-phenomenon',
  'team-phys',
  'team-plan-b',
  'team-preparation',
  'team-probl8m',
  'team-property',
  'team-prospects',
  'team-purity',
  'team-quazar',
  'team-quest',
  'team-reapers',
  'team-rebound',
  'team-refuse',
  'team-retrowave',
  'team-roccat',
  'team-rock',
  'team-s2pira',
  'team-sampi',
  'team-schwanek',
  'team-secret',
  'team-seeyou',
  'team-server-forge',
  'team-shift',
  'team-shutdown',
  'team-sigma',
  'team-singularity',
  'team-skillset',
  'team-skyfire',
  'team-skyline',
  'team-smoked',
  'team-solid',
  'team-solomid',
  'team-spirit',
  'team-swins',
  'team-trucknutz',
  'team-ubitum',
  'team-unique',
  'team-unity',
  'team-valipala',
  'team-verboten',
  'team-vertex',
  'team-vintage',
  'team-vitalerror',
  'team-vitality',
  'team-webone',
  'team-wheelchair',
  'team-wild-fire',
  'team-wild',
  'team-winfakt',
  'team-x',
  'team-xenex',
  'team-yetti',
  'team-yp',
  'team',
  'team1',
  'team123',
  'team30plus',
  'team7am',
  'teamchez',
  'teamfathom',
  'teamflippers',
  'teamisland256',
  'teamknaek',
  'teamkr-esport-club',
  'teamkr',
  'teamnicetry',
  'teamoff',
  'teamone',
  'teamorangegaming',
  'teamraised',
  'tegoedvoornaam',
  'teiko',
  'telos',
  'temp',
  'temperate',
  'tempest',
  'tempo-gaming',
  'tempo-storm',
  'tempos',
  'tempr',
  'tenerife-titans',
  'tension',
  'tera',
  'terror-otrodya',
  'terzy',
  'teveel-vlaams',
  'tgd',
  'tgj-esports',
  'tgj',
  'th3-reapers',
  'thats-my-friend',
  'the-agency-clan',
  'the-asylum',
  'the-babes',
  'the-big-dawgs',
  'the-black-company',
  'the-boats',
  'the-bombhogs',
  'the-borderless',
  'the-branded',
  'the-burgers',
  'the-cavity-crew',
  'the-chosen-few',
  'the-chozen-ones',
  'the-cuddlers',
  'the-dawn-of-scrumtavious',
  'the-dice',
  'the-equalizer',
  'the-forge',
  'the-foundation',
  'the-gate',
  'the-gatekeepers',
  'the-glecs',
  'the-goose-house',
  'the-guys',
  'the-homies',
  'the-hood-heroes',
  'the-huns',
  'the-imperial',
  'the-incas',
  'the-kittens',
  'the-last-resort',
  'the-lycans',
  'the-milk-boyz',
  'the-milkmen',
  'the-mixlegends',
  'the-mongolz',
  'the-oasis',
  'the-peons',
  'the-plague',
  'the-pokemons',
  'the-prodigies',
  'the-qube',
  'the-ranch',
  'the-roaches',
  'the-saints',
  'the-shield',
  'the-silly-squirrels',
  'the-ssc',
  'the-suga-show',
  'the-tinderellas-10',
  'the-union',
  'the-van-dyke-browns',
  'the-witchers',
  'theboysareback',
  'thedegenertates',
  'thedice',
  'thednc',
  'thedodgefathers',
  'thefree',
  'thegoodmen',
  'thehobbyists',
  'thelastresort',
  'thelizards',
  'themongolz',
  'theotherguys',
  'thesenseiladews',
  'thespensers',
  'thicc-nut',
  'third-impact',
  'threats',
  'throat-goats',
  'thunder-logic',
  'thunderbolts',
  'thunderflash',
  'tiger',
  'tiim',
  'timbermen',
  'time-waves',
  'tindergold',
  'titan',
  'titans-esports',
  'tlastresistance',
  'tmvg-rctic',
  'tmvg',
  'tnc-pro-team',
  'to-the-major',
  'to-the-moon',
  'toadsuckies',
  'toby-pyllistys',
  'tojiphysique',
  'token-gaming',
  'tokengmgwhite',
  'tone-setters',
  'tonka-truck',
  'tonyanderthals',
  'toofarcs',
  'toomuchvideogames',
  'top-black',
  'top-g',
  'top-model',
  'top-one-percent',
  'top-white',
  'topflare',
  'toplovers',
  'toptab-club',
  'torpedo',
  'torqued',
  'totale-pro',
  'totalepro',
  'totalne-gituwy',
  'totem',
  'tothebitterend',
  'totsugeki',
  'touch-down',
  'touch-point',
  'touch-the-crown',
  'trackpadgaming',
  'tragically-bad',
  'tranquillum',
  'trash-island',
  'trasko',
  'tredjefemman',
  'trenblone-users',
  'trench-foot',
  'triangletraders',
  'tricked-esport',
  'tricked',
  'trident-clan',
  'trident-esports',
  'trident',
  'trifecta-gaming',
  'triple5coped',
  'triton',
  'triumph',
  'trollingatbest',
  'tropa-suprema',
  'troya-legion',
  'true-ambition',
  'true-rippers',
  'trumptrain',
  'trust',
  'trypants',
  'trz',
  'tsetsegchid',
  'tsl-black',
  'tsm',
  'tsunami-academy',
  'tsunami-esports',
  'tsunami-rising',
  'tsunami-sirens',
  'tt-willhaben',
  'tugboat',
  'tuna-can-clan',
  'tuna-can-girth',
  'tundra',
  'tunneldwellers',
  'turbo-przyglupy',
  'turbo4funhavers',
  'turkey5',
  'turkey7',
  'turkishbhouse',
  'turow-zgorzelec',
  'turtispeek',
  'turtles-clan',
  'tuskem',
  'tuste-chopaki',
  'tutel',
  'tweam-gamers',
  'twentyr-gaming',
  'twice',
  'twilight-thunderclap',
  'twisted-minds',
  'twistzz-lovers',
  'twodads',
  'twointheshirt',
  'txt-gaming',
  'tyler-blows',
  'tyloo',
  'u-cluj-esports',
  'ubiteam',
  'ucam-esports-club',
  'uchoa',
  'ucsr',
  'udem-sales-babz',
  'udp-romania',
  'udp',
  'ugk',
  'uhka-esports',
  'uk',
  'ukenergycrisis',
  'ul-red',
  'ultimatedarklordwizardz',
  'ultimax-gaming',
  'umx',
  'under-score',
  'under-the-radar',
  'underground-cs',
  'uneven',
  'unfilteredchaos',
  'ungent',
  'ungentium',
  'unicorns-of-love',
  'union',
  'union88',
  'unique-team',
  'unique',
  'uniquestars',
  'united-nutsacks',
  'united5',
  'unitedstatesarmy-esports',
  'unity-esports',
  'unity',
  'universal-soldiers',
  'uniway',
  'unjustified-ac',
  'unjustified',
  'unknowns',
  'unlucky-boys',
  'unluk000',
  'uno-mille',
  'unplanned',
  'unravel',
  'unsigned',
  'unskilled',
  'untitled',
  'unwillordinary',
  'uofdenver',
  'up1',
  'updraft',
  'urnan-clan',
  'usa-synapse',
  'ussr-team',
  'utm-esports',
  'utopia-',
  'utopia-clan',
  'uv1',
  'uvu-esports',
  'ux-gaming',
  'uyu',
  'uzumakii',
  'v-fortis-fe',
  'v12already',
  'v1dar-gaming',
  'v1dar',
  'vac-banned',
  'vae-victis',
  'valhalla-vikings',
  'valhalla',
  'valianceandco',
  'validity-x',
  'valkence',
  'valkyrie',
  'valla',
  'vallea',
  'valorant-playerz',
  'valorant',
  'valorix',
  'valors',
  'vamos',
  'vanaskap',
  'vanir',
  'vantage',
  'vantageesc',
  'variance-gaming',
  'vaseline-worms',
  'vasytystactic',
  'vault',
  'vega-e-sport',
  'vega-squadron',
  'vega96',
  'veloce-esports',
  'velox-argentina',
  'velox',
  'velox',
  'veloxgg',
  'veloz',
  'venary-talent',
  'vence',
  'venom-esports',
  'venom',
  'verboten',
  'verdant',
  'verdict',
  'vertex-esc',
  'vertex',
  'verum',
  'verygames',
  'veryseriousgmrz',
  'veto',
  'vex-dragons',
  'vex',
  'vexed-gaming',
  'vexed',
  'vexed',
  'vexo-purple',
  'vexx-gaming',
  'vg',
  'vg',
  'vgflashgaming',
  'vibe-check',
  'vibe-esports',
  'vice-versa',
  'vici-gaming',
  'vici',
  'vicious-kitties',
  'victorum',
  'victory-zigzag',
  'victus',
  'vidstodu',
  'viina-ga',
  'viking',
  'vikings',
  'vilay-esport',
  'vilay',
  'villainarc',
  'villainous',
  'villains',
  'vilok-kapustbi',
  'vination-esports-e',
  'vincam-elite',
  'vindicta',
  'vinkelparty',
  'vintage',
  'viperio',
  'vireo-pro',
  'vireo',
  'virosity',
  'virtual-genesis',
  'virtualcasino',
  'virtue-gaming',
  'virtus-pro',
  'virtus',
  'visio',
  'vision-gaming',
  'vision',
  'visu-gaming',
  'visu',
  'vitality',
  'vitone',
  'viva-algeria',
  'viva-la-viv',
  'vivid-utd',
  'vivo-keyd',
  'vivo',
  'vixit',
  'vizard',
  'vkbest',
  'vlone-wolves',
  'vlonely',
  'vnd',
  'void',
  'voidic',
  'volgare',
  'vollaufnase',
  'voltage-essm',
  'voracity',
  'vorpal-swords',
  'voyvoda',
  'vp-angels',
  'vp',
  'vpprodigy',
  'vslash',
  'vvide-swingers',
  'vws-gaming',
  'vybn',
  'w-men-ai-nanren',
  'w2c',
  'w4stedt4l3nts',
  'w7m-esports-ac',
  'w7m-esports',
  'w7m-fe',
  'w7m-gaming',
  'w7m',
  'w81',
  'wah',
  'waitforit',
  'waitfortag',
  'waldelocker',
  'wallahi',
  'wallahi123',
  'waltuh',
  'wambit',
  'wanted-goons',
  'wap',
  'warlegend',
  'warmaker',
  'warriors-international',
  'warriors',
  'warstyle',
  'wartex-eu',
  'warthox-esport',
  'warthox',
  'washdup23yrolds',
  'washed-grandpas',
  'washed-squad',
  'washedbutdirty',
  'wasted-potential',
  'wasted-talents',
  'wastedpotent1al',
  'wat-da-dog-doin',
  'waterboarding-fan-club',
  'waterdogs',
  'wave-esports',
  'wavey',
  'waxbyz',
  'wayward-sons',
  'we-almost-had-it',
  'we-are-silly',
  'we-back',
  'we-dem-boyz',
  'we-got-game',
  'webspell-gaming',
  'webspell',
  'websterz',
  'weeabooboos',
  'weeknd-warriors',
  'wegaanvoorgoud',
  'were-so-back',
  'werunthisplace',
  'wesports',
  'western-wolves',
  'wg-bandits',
  'whats-it-called',
  'when-deez-squad',
  'whiffvagninn',
  'white-flames',
  'white-iverson',
  'white-rabbit-am',
  'white-rabbit',
  'whiteclowns',
  'whykick-team',
  'whysoserious',
  'wicked-esports',
  'wicked',
  'wiff-check',
  'wild-panthers',
  'wild-wolverines-reborn',
  'wild',
  'wildcard',
  'wildcats',
  'wimpy-chimps',
  'wind-and-rain',
  'windigo-gaming',
  'windigo',
  'windigo',
  'windingo',
  'windingogg',
  'wings-gaming',
  'wings-up',
  'winland',
  'winnercompany',
  'winside',
  'winstrike-team',
  'winstrike',
  'winterfox',
  'wisla-krakow',
  'wisp',
  'wizard',
  'wizards-e-sports-club',
  'wizards',
  'wklemmt',
  'wlgaming',
  'woke-mob',
  'wolfpack',
  'wololos',
  'wolsung',
  'wooting-peek',
  'working-men',
  'worm-clan',
  'worst-players',
  'wortex',
  'wr3k',
  'ws-esports',
  'wtsickness',
  'wu-tang-mix',
  'wwplayers',
  'wygers-argentina',
  'wygers',
  'wysix',
  'wzm-de-father',
  'x-city-m2',
  'x-gamer',
  'x-gaming',
  'x-kom-team',
  'x-squad',
  'x',
  'x13',
  'x6tence-galaxy',
  'x6tence',
  'x7-team',
  'xapso',
  'xastris',
  'xelectro-esport',
  'xenex',
  'xgame',
  'xi-antaichanggui',
  'xi-esport',
  'xi',
  'xim-aimers',
  'xing-xing',
  'xiyarmix',
  'xmplfy-black',
  'xmplfy',
  'xp-academy',
  'xp-ankh',
  'xp-ansuz',
  'xp-project',
  'xpc-gaming',
  'xperion-nxt',
  'xplicit',
  'xset',
  'xtinct',
  'xtream-e-sport',
  'xtrem-skilled',
  'xuneas',
  'xy-esports',
  'xy',
  'xyston',
  'y-create',
  'yalla',
  'yawara-e-sports',
  'yawara',
  'yeah-gaming',
  'yeah',
  'yengi',
  'yenicherry',
  'yenkee-academy',
  'yeyo',
  'yippie',
  'yk-gaming',
  'yk',
  'ymca-esports',
  'yng-sharks',
  'yngstxrs',
  'ynity',
  'ynt',
  'yod-esports',
  'yod',
  'yomi-6-brigade',
  'yooooooo',
  'youfeelmee',
  'young-glorians',
  'young-gods',
  'young-ninjas',
  'young-rodents',
  'youngkings',
  'youngstars',
  'youreanus-llc',
  'youseehim',
  'youwork2ndshift',
  'ystads-if',
  'yungrichniceguy',
  'z-nation',
  'zagreb-360-gaming',
  'zark',
  'zashkvar',
  'zawisza-esports',
  'ze-pug-godz',
  'zeal22',
  'zearless',
  'zelka-reborn',
  'zelka-revival',
  'zengaming',
  'zenith',
  'zenithfps',
  'zennit',
  'zennox-gaming',
  'zennox',
  'zenq-ninjas',
  'zero-gravity',
  'zero-hero',
  'zero-marksmen',
  'zero-tenacity',
  'zerofailure-na',
  'zerozone',
  'zeta',
  'zigma',
  'znation',
  'zomblers',
  'zonked-academy',
  'zonked-e-sports',
  'zoomnboom',
  'zorka',
  'zotix',
  'zrrttgaming',
  'ztraf',
  'discotaxi',
  'datura',
  'csfail',
  'priority-ice',
  'space-force',
  'team-flow',
  'taco',
  'pokemons',
  'voice-enable-0',
  'big-water',
  'limitlessangels',
  'zobrux',
  'nujabes',
  'kazoo-krew-zoo',
  'nomads',
  'mcon-esports',
  '1h-gaming',
  'bluesolo',
  'reasongaming',
  'sway-esports',
  'fake-esport',
  'vellamo',
  'jeremys-au',
  'mrvn-esports',
  'the-gu-krew',
  'flares-esport',
  'another-victim',
  'poceblo',
  'nemesis',
  'factory-reset',
  'gruv-co',
  'depo-premium',
  'x-city',
  '5x404',
  'ultrawhite',
  'feels-im-man',
  'soprano-cwell',
  'otherscantbeat',
  'de-stroyed-p2',
  'lionheart',
  'elysian-fields',
  'beendope',
  'fearferox',
  'wwaves',
  'k10-fwrd',
  'dreamstolegends',
  'obscure-club',
  'straykids',
  'lazer-cats',
  'the-jizz-brozz',
  'vortex-vanguard',
  'ltd',
  'full-circle',
  'f5-esports',
  'redpack-esports',
  'dexterity',
  'retirement-home',
  'wnt',
  'ocb-nxt',
  'blessed',
  'lausanne-els',
  'lostrecognition',
  'huge-sweaty',
  'halal5',
  'raido',
  'senshi-bnl',
  'turritos',
  'part-timers',
  'jahsdnmasjdm-v2',
  'kingdom',
  'capybara-esport',
  'team-lampa',
  'overfrag-yng',
  'psiaku',
  'fastmen',
  'bongripper',
  'ence-prospects',
  'needdoctor',
  'gtz-esports',
  'mightywolves',
  'puhevika',
  'bailando',
  'yondaleathletic',
  'hakka',
  'legends',
  'gyrocopter-ua',
  'agency-clan',
  'elfeex-team',
  'phantoms',
  'darias-gamers',
  'kyouasa',
  'team-dna',
  'nefour',
  'team-sufarex',
  'psychok1ds',
  'hyperspirit',
  'thunderflashtm',
  'nexusac',
  'kaffa',
  'qlimax',
  'split-esc',
  'arktos-esports',
  'evoplay',
  'nekst',
  'havens',
  'pivstar',
  'inz-5',
  'what-doink',
  'rebel-stars',
  'pbs-money-crew',
  'uc',
  'permitta-ac',
  'lukketid',
  'sunnuntai',
  'da-jokas',
  'eeffoc',
  'whynot',
  'esmagab',
  'just-a-glimpse',
  'private',
  'team-no-name',
  'm33-x-neimark',
  'myth-esports',
  'rcave',
  'gruspivteam',
  '4-aguzka-u-shef',
  'mentalproblem',
  '2077esports',
  'consanguin',
  'acl-esports',
  'e-xolos-lazer',
  'crunchybagels',
  'enterprise-gen',
  'synergy-esports',
  'diversity',
  'hoqeo',
  'anita-max-wynn',
  'god-bets',
  'grand-masters',
  'nopression',
  'lizard-wizards',
  'teiko-high-lfo',
  'el-es-pi-di',
  'pe69ta',
  'elementgamingc',
  'amigosi',
  'injury',
  'synchronicity',
  'team-instinct',
  'mronz',
  'goashax-algeria',
  'wartex',
  'lt-mix',
  'netctrlv',
  'lenovo-honved',
  'pugstarmagnates',
  'eldsjalar',
  'proqsmoneycrew',
  'ninj-gg',
  '1boomer4zoomer',
  'akimbo',
  'g3-',
  'bait-og-tilt',
  'old-boys-club',
  'censored',
  'gl-academy',
  'log-esports',
  'abcd',
  'exo',
  'frauds',
  'ninjaparentz',
  'rentner',
  'addicted-esport',
  'sin',
  'tempest-gaming',
  'gorilla-mode',
  'fat-esport',
  'genesis',
  'dms',
  'ocean-gaming',
  'pwrgum-red',
  'ah-mirrored',
  'cph-wolves',
  'ensky-esports',
  'gecko-esports',
  'vaiafatima',
  'little-red-gang',
  'illuminargaming',
  'olmpq',
  'tamu-cs2',
  'drunkdrivers',
  'sd-gaming',
  'sedated',
  '1diotteam',
  'east-melons',
  'mostly-garbage',
  'sassy-sunshines',
  'shaman',
  'fluffy-youngs',
  'bitshock',
  'skillzone',
  'lethalcompanycs',
  'five-legends',
  'excuse-me',
  'toptab',
  'cl-showbobs',
  'tsl-white',
  'artemis',
  'aka-hero',
  'persona-esports',
  'samurai',
  'brain-busters',
  'victory-royale',
  'lng-',
  'desire-2-excel',
  'astronavti',
  'prism',
  'world-reavers',
  'lwcats',
  'midwest-farmers',
  'ratkiller',
  'all-elementz',
  'waterboys',
  'nox-gaming',
  'peep',
  '9-lives',
  'paceka',
  'peltisissiparvi',
  'fentanylfriends',
  'baks-esports',
  'larmo',
  'bijoux',
  'virtuoso-squad',
  'flg',
  'costco-guys',
  'lego',
  'power-rangers',
  '501st',
  'evilspacewzards',
  'indubitable',
  'sneak-nade-prac',
  'teamhydegg',
  'csta',
  'stay-frosty',
  '500kgclub',
  'tnc-esports',
  'tokyo',
  'outgoingesports',
  'uneven-academy',
  'retony',
  'kedusexdenuit',
  'b2tg',
  'carbonx-esports',
  'superioresports',
  'vitaplur-gum',
  'anime-avatars',
  'cristianoronald',
  'never-named',
  'smile',
  'perplexity',
  'kellyclan',
  'boomer-tumor',
  'zynthusiasts',
  'zonked-main',
  'hava',
  'pewnoscsiebie',
  'oxuji-mint',
  'agenty',
  'ruby',
  'tokyomanji',
  'fortress-main',
  'pro-goofballs',
  'aka-hero-int',
  'tracksuitgaming',
  'marsupy-gaming',
  'le-club',
  'sapper',
  'mopse',
  'abg-hunters',
  'realistic',
  'powerfinesse',
  'ut-austin',
  'avalon',
  'silencers-',
  'no-love',
  'free-frugz',
  'webangin',
  'floki-main',
  'x5',
  'ravagers',
  'roots-gaming',
  'wg-outlaws',
  'zerovariant',
  'epoka-lodowcowa',
  'aztec-talent',
  'regain',
  'riseup-esport',
  'most-hated',
  'tba-ecf',
  'quixal',
  'instant-crush',
  'monaspa-esports',
  'vexar',
  'diamant-esports',
  'qtuquerestamole',
  'ihg-junior',
  'pawer-kambak',
  'attax-evo',
  'pbyty3',
  'cash-cows',
  'pig-gaming',
  'unluckyday',
  'no-bystanders',
  'deli-cinq',
  'ex-diverse',
  'guaranteed-raw',
  'thelastdragons',
  'h0pe1ess',
  'mb-university',
  'apx-energy',
  'odyssey',
  'eternal-premium',
  'regnum4games',
  'allinners',
  'the-boys',
  'ex-ec-brugge',
  'austrian-force',
  'pulse',
  'who-cares',
  'bangers',
  '10-yrs-of-main',
  'donstu-esports',
  'creed',
  'sheremetjevo',
  'modern-players',
  '5goblinz',
  'money-men',
  'fork-lifters',
  'dotstats',
  'infernal-void',
  'divergent',
  'chroma',
  'caldya',
  'vgtu-esports',
  'son-esports',
  'madanascar',
  'apogee',
  '17-mph',
  '500-rush',
  'gameagents-ac',
  'razed-esports',
  'amped-red',
  'strafe-cru',
  'omen-esports',
  'mach-5',
  'lazerrfreeks8',
  'blueotter-karma',
  'playfire',
  'need1-agency',
  'forgetabitcuh',
  '500esports',
  'ozempic-5',
  'boiler-boys',
  'scrub-daddy',
  'jblinky',
  'depo-team',
  'be-five',
  'jits',
  'habibi',
  'lukgudplaygud',
  'alter-iron-club',
  'kill-la-kill',
  'relegated-arnes',
  'road2kato2034',
  'belfast-storm',
  'kay-team',
  'kunai',
  'liberals',
  'clowncar',
  'dapper-north',
  'center-gaming',
  'nolightgaming',
  'frauds-academy',
  'long-season',
  'honeyharvesters',
  'hotu-x-elga',
  'ah-nightwatch',
  'stoic-inter',
  'inroads-esports',
  'goblinaim',
  'o-block-esports',
  'team-comcast',
  'bad-news-bozos',
  'the-pug-jug',
  'ima-problem',
  'masso-chiasson',
  'fisher-college',
  'regnum4games-ac',
  'noobz',
  'beerleagyue',
  'team-fantasy',
  'marcaregistrada',
  'immigrants-peek',
  'might-x-gripgel',
  'holesmokers',
  'poohank',
  'havocgg',
  'infinite-talent',
  'm4',
  '1tin',
  'milked',
  'bangs',
  'droidjays',
  'mercedesspoiler',
  'new4ge-esports',
  'peekers-adv',
  'thegns',
  'bloons',
  'myskill',
  'insta-baddies',
  'codmaniacs',
  'swingers',
  '1gang',
  'chaoz-gold',
  'forze-reload',
  'kubixpulse',
  'eefsports',
  'delta-division',
  'bomb-squad',
  'problematic',
  'team-aether',
  'oshikousei',
  'zone-of-victory',
  'admiralsgg',
  'imperishable',
  'annexesports',
  'redactedsociety',
  'vibe',
  'gecko-main',
  'newballs',
  'dreamwalkers',
  'eztbh',
  'vnt',
  'lnw',
  'prestige-esport',
  'saint-sinners',
  'wopa-esport',
  'uoft-varsity',
  'doski',
  'super-phalanx',
  'dream-fire',
  'klan-fontan',
  'podredbulom',
  'ek-vellamo',
  'radiantkeysus',
  'rur-esports',
  'moggers',
  'mf',
  'f1lthy-puggers',
  'ohio-meat-wagon',
  'ratajkowski-fc',
  'small-rejuice',
  'undefined',
  'finalform',
  'vitriol',
  'pisyatdva',
  'lays',
  'sayonara',
  'themoneyteam',
  'antigrind',
  'zomblersacademy',
  'casta',
  'quitgoats',
  'fnp-esports',
  'input',
  'roman-chimpire',
  'theory',
  'constrictgaming',
  'unnamed-project',
  'relic',
  'inferno-gaming',
  'ex-xi-esport',
  'g2-ares',
  'monkeypush',
  'moonlight',
  'team-leveling',
  'soulsheart',
  'impulse-gw',
  'deorum',
  'odjur',
  'slake',
  'www',
  'ding-cuts',
  'void-gaming',
  'the-secret-club',
  'hall-of-famers',
  'chissoku-suru',
  'soulseekers',
  'poisonous-bunny',
  'old-gods',
  'cloud-gaming',
  '-97f',
  'kebab-sultans',
  'viperioacademy',
  'dragon-esports',
  'ruvious',
  'smakolysze',
  'fullgaz',
  'drillas-academy',
  '3plus2',
  'fullshockgamers',
  'southern5',
  'dancompany',
  'eys-esports',
  'prime-bulls',
  'procamp-ua',
  'pant-gang',
  'lock-in',
  'vizora-esports',
  'baubylos',
  'permitta-ua',
  '5warikov',
  'dangle',
  'we-play-naked',
  'priority-hollow',
  'genesium',
  'veni-vidi-vici',
  'platoon-beta',
  'cyber-ninjas',
  'easypeasy',
  'krakenblack',
  'terraesportsp',
  'xxx-',
  'kkgod',
  'danishpotatoes',
  'ycreate',
  'supersoakerclub',
  'skibidigaming',
  'rajutkundit',
  'ngse',
  'realmadrid',
  'newd',
  'audacity-prime',
  'legion-biack',
  'the-gentlemen',
  'elite-riflers',
  'tandourians',
  'kryptonic',
  'mirai-gaming',
  'revitalize',
  'enrage',
  'on-some-bs',
  'sequel',
  'squadra-azzura',
  'okay-synergy',
  'team-mythic',
  'takeflyte',
  's8-x-soulgame',
  'valkyria',
  'smalsiai',
  'chaos-champions',
  'cyberspace-ua',
  'balloonboysclub',
  'gouc',
  'pew-pew-pandas',
  'punjabi-pirates',
  'wmdwm',
  'd1nk-esports',
  'hoes-no-jutsu',
  'kajo',
  'somalians',
  'gcsy',
  'fake-do-biru',
  'smoke-gaming',
  'imp-valkyries',
  'lodis-ac',
  '13-avg',
  'nitrogg',
  'colales-de-b',
  'ys-talents',
  'ssp-main',
  'rederebel',
  'southampton',
  'team-esky',
  'zennox-academy',
  'sangal-1xalters',
  'tryhearts',
  'mind-benders',
  'cubera',
  'csde',
  'in-coming',
  'clutchmonkeys',
  'aml',
  'team-fbeast',
  'second-nature',
  'helenkellerpeek',
  'also',
  'saucemen',
  'rey-esports',
  'leca-fc-esports',
  'the-matti-show',
  '40yearoldpeek',
  'lastdinnerparty',
  'goopt',
  'xgod-arena',
  'oldbutgold',
  'wolves',
  '7x',
  'rat-gang',
  'ohhnoooo',
  'creatorpeek',
  'victores-sumus',
  'tricksters',
  'kindling-gaming',
  'dtl',
  'lan-party-hotel',
  'boring-player',
  'malayindounited',
  'g0dbless',
  'phase-team',
  'chaox-esports',
  'patinsd-ferrari',
  'flat-earth',
  'ngh',
  't-hamsters',
  'cw-academy',
  'esprots',
  'cbump-team',
  'horizon-esports',
  'mgl-power',
  'tsegtaslal',
  'cutiewutie',
  'goldenv',
  'grip-n-spin',
  'team-eclipse',
  'goblin-esports',
  'da-kore',
  'vitaplur-plasma',
  'aomaesports',
  'bez-zarplatbl',
  'ember',
  'c4pybaras',
  'detdom-boys',
  'dreams2legends',
  'virtal-limits',
  'straight2killin',
  'elo-terrorists',
  'asian-invasion',
  'werty-ac-aqua',
  'caracol-gigante',
  'beyond-ruim',
  'churrrros',
  'polteam',
  'lycus-empire',
  'force-one-store',
  'bass-masters',
  'dozera',
  'hyro',
  'bink',
  'bozos',
  'exusiai',
  'gray-wolf',
  'vantage-academy',
  'mcpork',
  't-up',
  'whatever',
  'armitra-esports',
  'noreason-gaming',
  'insilio-school',
  'yocrew',
  'full-tilt',
  'ego-accendent',
  'enjoy1',
  'real-esports',
  'blueprint',
  'sskt',
  'the-bus-crew',
  'werty-ac-blood',
  'devils-one',
  'taking-it-deep',
  'chicken-coop',
  'scubatuba',
  '925-club',
  'looking4sponsor',
  'team-gdt',
  'free-elo',
  'f1washedcar',
  '5demon',
  'anatolian-foxes',
  'misfire',
  'mana',
  'yakogo-didkya',
  'lux-esport',
  'the-vynz',
  'unity-ac',
  'reflexx',
  'unitywave',
  'denizbank-iwc',
  'gamblersanonymo',
  'clase-azul',
  'evilelk',
  'the-trongolz',
  'centralfinancia',
  'golira',
  'anything-else',
  'm-agents',
  'ze-republic',
  'wu-tang-mixx',
  'dire',
  'bizon-prime',
  'three11',
  'greatapes',
  'take-flyte-eu',
  'team-peanuts',
  'the-leftovers',
  'glitch',
  'onslaught',
  'arc-dynasty',
  'dreamer-esports',
  'tsunami',
  'frauds-season',
  'vatican-city',
  'imperators',
  'wafaw',
  'noobindisguise',
  'terraesportsb',
  'never-say-never',
  'quazar-2',
  'oblivion',
  'inet-esport',
  'ase-pwr',
  'royals-academy',
  'models',
  'leca-fc-aurora',
  'virtualgangstas',
  'mollitiem',
  'socks-sniff',
  'kulun4uk',
  'rocean-academy',
  'roa-esports',
  'silly-strikers',
  'gaidamaks',
  'infinity-esport',
  '-duckx',
  'megamega',
  'hummingbird',
  'godamn',
  'team-gelo',
  'sthlm-city',
  'huskgg',
  'skyfury',
  'energie',
  'hoax-fan-club',
  'giddeygoons',
  'ek-violet',
  'vanirgg',
  'flourish-gaming',
  'above-team',
  'vaksa',
  'nightmare-esp',
  'dirtjackerz',
  'backwhenever',
  'straightoutfire',
  'focus',
  'ep',
  'pandemoniumgg'
];
