import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from "react-bootstrap";
import { HLTVMatchData, RoundData, RoundsMetadata } from "../utils/types";
import { fetchFromApi, fetchFromS3 } from '../utils/api';
import { Playlist } from '../utils/types';
import * as zip from "@zip.js/zip.js";
import { clearFaultyFrames, generatePlayerShortNames, team_names_equal } from "../utils/teams";
import Analyse from '../components/Analyse';
import { CachedProjectiles, calculateProjectileCache, clearDuplicateGrenadeWeaponFires, clearExtraFires, fixRoundDataUniqueIDs, generateRoundDataTeamKeys, offsetTicksToStartFromZero, precalculateBlindedPlayers } from '../utils/projectile';
import { fixHasBombCs2, fixWeaponInventoryCs2 } from '../utils/weapons';
import { useLocation } from 'react-router-dom';
import MatchFilterPopup from '../components/MatchFilterPopup';
import { match } from 'assert';


export interface HLTVMatchesData {
  [key: string]: HLTVMatchData,
}

export interface RoundsData {
  [key: string]: RoundData,
}

// This needs to match /round_batch in the API
interface RoundKey {
  matchId: string;
  mapName: string;
  roundNum: number;
  t_team_key: string;
  ct_team_key: string;
  roundUrl: string | undefined;
}

const getRoundKey = (roundData: RoundData): string => {
  return `${roundData.matchId}.${roundData.mapName}.${roundData.roundNum}`;
};

const getInitialPlaylist = (): Playlist | undefined => {
  const sessionStorageItem = sessionStorage.getItem('playlist-analyse');
  if (sessionStorageItem) {
    return JSON.parse(sessionStorageItem) as Playlist;
  }
  const localStorageItem = localStorage.getItem('playlist-analyse');
  if (localStorageItem) {
    return JSON.parse(localStorageItem) as Playlist;
  }
  return undefined;
};

const AnalyseLoader = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const returnTo = queryParams.get('returnTo');

  const [playlist, setPlaylist] = React.useState<Playlist | undefined>(getInitialPlaylist());
  const [showMatchesPopup, setShowMatchesPopup] = useState(playlist === undefined);
  const [roundsData, setRoundsData] = useState<RoundsData>({});
  const [projectileCache, setProjectileCache] = useState<CachedProjectiles>({});
  const [canReset, setCanReset] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchRoundUrls = async (rounds: RoundKey[], signal: AbortSignal) => {
    const requestBody = {
      rounds: rounds.map(r => ({ matchId: r.matchId, mapName: r.mapName, roundNum: r.roundNum }))
    };

    const roundResponse = await fetchFromApi('/round_batch', {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json'
      },
      signal,
    });

    return await roundResponse.json();
  };

  const processRoundDataObj = async (
    roundDataObj: any,
    mapName: string,
    playlist_team_key: string,
    ct_team_key: string,
    t_team_key: string,
    signal: AbortSignal) => {

    if (signal.aborted) {
      console.log('Loading operation aborted before starting');
      return;
    }
    const onAbort = () => {
      console.log('Loading operation aborted during execution');
    };

    signal.addEventListener('abort', onAbort);
    try {
      fetchFromS3(roundDataObj.roundUrl, { method: 'get', referrerPolicy: 'no-referrer', signal })
        .then(async roundS3Response => {
          const blob = await roundS3Response.blob();
          const zipFileReader = new zip.BlobReader(blob);
          const zipReader = new zip.ZipReader(zipFileReader);

          let roundData: RoundData | null = null;

          for (const entry of await zipReader.getEntries()) {
            const zipTextWriter = new zip.TextWriter();
            const strContent = await entry.getData(zipTextWriter);
            const jsonObj: RoundData = JSON.parse(strContent);
            clearExtraFires(jsonObj);
            clearDuplicateGrenadeWeaponFires(jsonObj);
            clearFaultyFrames(jsonObj);
            generatePlayerShortNames(jsonObj);
            jsonObj.matchId = roundDataObj.matchId;
            jsonObj.mapName = mapName;
            roundData = jsonObj;
          }

          if (roundData !== null) {
            fixRoundDataUniqueIDs(roundData);
            offsetTicksToStartFromZero(roundData);
            generateRoundDataTeamKeys(roundData, ct_team_key, t_team_key);
            const roundKey = getRoundKey(roundData);
            const cachedProjectiles = calculateProjectileCache(roundData, mapName);
            setProjectileCache(prevCache => ({ ...prevCache, ...cachedProjectiles }));
            precalculateBlindedPlayers(roundData);
            if (roundData.cs_version === "cs2") {
              fixWeaponInventoryCs2(roundData);
              fixHasBombCs2(roundData);
            }

            if (signal.aborted) {
              return;
            }
            setRoundsData(prevState => ({ ...prevState, [roundKey]: roundData as RoundData }));
          } else {
            console.warn(`Failed to load data for round ${roundDataObj.roundnum} of match ${roundDataObj.matchId}`);
          }
        }
        );
    } catch (error) {
      console.error('Error in processRoundDataObj:', error);
    } finally {
      signal.removeEventListener('abort', onAbort);
    }
  };

  useEffect(() => {
    if (!playlist) {
      return;
    }

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const fetchAndSetData = async () => {
      setIsFetching(true);
      // Remove rounds that are not in the new playlist
      const roundsToRemove: string[] = [];
      Object.keys(roundsData).forEach(roundKey => {
        const round = roundsData[roundKey];
        if (round.matchId && !(round.matchId in playlist.rounds)) {
          roundsToRemove.push(roundKey);
        }
      });

      // Remove all rounds which are not the current playlist mapname
      Object.keys(roundsData).forEach(roundKey => {
        const round = roundsData[roundKey];
        if (round.mapName && round.mapName.replace('de_', '') !== playlist.mapname) {
          roundsToRemove.push(roundKey);
        }
      });

      const leftOverSize = Math.max(Object.keys(roundsData).length - roundsToRemove.length, 0);
      setRoundsData(prevState => {
        const newState = { ...prevState };
        roundsToRemove.forEach(roundKey => delete newState[roundKey]);
        return newState;
      });

      const MAX_ROUNDS = 200;
      const mapName = 'de_' + playlist.mapname;

      const roundsToFetch: RoundKey[] = [];

      // Find rounds that are not yet in the current roundsData
      Object.entries(playlist.rounds).forEach(([matchId, roundMetas]) => {
        roundMetas.forEach(roundMeta => {
          const roundKey = `${matchId}.${mapName}.${roundMeta.roundnum}`;
          if (!(roundKey in roundsData) && roundsToFetch.length + leftOverSize < MAX_ROUNDS) {
            roundsToFetch.push({
              matchId: matchId,
              mapName: mapName,
              roundNum: roundMeta.roundnum,
              t_team_key: roundMeta.t_team_key,
              ct_team_key: roundMeta.ct_team_key,
              roundUrl: undefined,
            });
          }
        });
      });

      // Split roundsToFetch into exponentially growing chunks
      const chunkedRounds = [];
      let chunkSize = 5; // Starting chunk size
      for (let i = 0; i < roundsToFetch.length;) {
        chunkedRounds.push(roundsToFetch.slice(i, i + chunkSize));
        i += chunkSize;
        chunkSize *= 2; // Double the chunk size
      }

      for (const chunk of chunkedRounds) {
        try {
          const roundUrlsResponse = await fetchRoundUrls(chunk, signal);
          for (const roundDataObj of roundUrlsResponse.body) {
            const r = roundsToFetch.find(r => r.matchId === roundDataObj.matchId && r.mapName === roundDataObj.mapName && r.roundNum === roundDataObj.roundNum);
            if (r !== undefined) {
              processRoundDataObj(roundDataObj, mapName, playlist.team_key || '', r.ct_team_key, r.t_team_key, signal);
            }
          }
        } catch (err) {
          if (err instanceof Error) {
            console.error(err.message);
          } else {
            console.error(err);
          }
        }
      }
      setIsFetching(false);
    };

    fetchAndSetData();

    return () => {
      if (isFetching) {
        abortControllerRef.current?.abort();
      }
    };
  }, [playlist]);

  return <>
    <MatchFilterPopup
      show={showMatchesPopup}
      initialTeamKey={playlist?.team_key || undefined}
      initialMapname={playlist?.mapname || ''}
      initialMatchIds={playlist ? Object.keys(playlist.rounds) : []}
      handleAnalyse={(playlist: Playlist) => {
        abortControllerRef.current?.abort('New playlist selected');
        abortControllerRef.current = new AbortController();
        setPlaylist(playlist);
        setShowMatchesPopup(false);
        sessionStorage.setItem('playlist-analyse', JSON.stringify(playlist));
      }}
      handleCloseModal={() => {
        setShowMatchesPopup(false);
        if (canReset) {
          if (returnTo !== null && !returnTo.includes('analyse')) {
            window.location.href = returnTo;
          } else {
            window.location.href = '/';
          }
        }
      }}
    />
    {playlist &&
      <Analyse
        roundsData={Object.keys(roundsData).map(key => roundsData[key])}
        projectileCache={projectileCache}
        playlist={playlist}
        csVersion={roundsData !== undefined && Object.keys(roundsData).length > 0 ? roundsData[Object.keys(roundsData)[0]].cs_version || 'csgo' : 'cs2'}
        onSelectMatchesClick={() => {
          setShowMatchesPopup(true);
          setCanReset(false);
        }}
      />}
    {!showMatchesPopup && (roundsData === undefined || Object.keys(roundsData).length === 0) && <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <span><Spinner animation="border" /><span className="loading-label">Loading...</span></span>
    </div>}
  </>
};

export default AnalyseLoader;