import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import CustomModalHeader from './CustomModalHeader';

type SignInButtonProps = {
  onLogin: () => void;
  onForgotPassword: () => void;
  label?: string;
};

const SignInButton: React.FC<SignInButtonProps> = ({ onLogin, onForgotPassword, label }) => {
  const [showSignIn, setShowSignIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [signInError, setSignInError] = useState("");
  const [confirmationSent, setConfirmationSent] = useState(false);

  const handleSignIn = async () => {
    try {
      await Auth.signOut();
      const user = await Auth.signIn(email, password);
      onLogin();
      setShowSignIn(false);
    } catch (error: any) {
      if (error.code === 'UserNotConfirmedException') {
        resendConfirmationCode();
      } else {
        console.log('Error signing in', error);
        setSignInError(error.message);
      }
    }
  };

  const handleConfirmSignIn = async () => {
    try {
      await Auth.confirmSignUp(email, confirmationCode);
      handleSignIn();
    } catch (error: any) {
      console.log('Error during confirmation', error);
      setSignInError(error.message);
    }
  };

  const resendConfirmationCode = async () => {
    try {
      await Auth.resendSignUp(email);
      setConfirmationSent(true);
      setSignInError("A new confirmation code has been sent to your email. Please enter it here.");
    } catch (error) {
      console.log('Error resending confirmation code', error);
    }
  };

  const handleModalClose = () => {
    setShowSignIn(false);
    setSignInError("");
    setConfirmationSent(false);
  };

  const handleModalOpen = () => {
    setShowSignIn(true);
  };

  const handleForgotPassword = () => {
    onForgotPassword();
    handleModalClose();
  };

  return (
    <>
      <Button variant="outline-secondary" size="sm" onClick={handleModalOpen}>
        <i className='bi bi-door-open' style={{marginRight: '2px'}} /> {label ? label : "Login"}
      </Button>

      <Modal show={showSignIn} onHide={handleModalClose}>
        <CustomModalHeader />
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="Email" 
                onChange={(e) => setEmail(e.target.value)} 
                onKeyDown={(e) => e.stopPropagation()}
                autoFocus 
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Password" 
                onChange={(e) => setPassword(e.target.value)} 
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Form.Group>

            {confirmationSent && 
            <Form.Group className="mb-3">
              <Form.Label>Confirmation Code</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter confirmation code" 
                onChange={(e) => setConfirmationCode(e.target.value)} 
                onKeyDown={(e) => e.stopPropagation()}
              />
            </Form.Group>
            }

            {signInError && <Alert variant="danger">{signInError}</Alert>}
            <Button variant="link" style={{ padding: 0, marginBottom: '1rem', float: 'right' }} onClick={handleForgotPassword}>
            Forgot password?
            </Button>
            <Button variant="primary" onClick={confirmationSent ? handleConfirmSignIn : handleSignIn} style={{width: '100%'}}>
              <i className='bi bi-door-open' style={{marginRight: '2px'}} /> {confirmationSent ? "Confirm & Login" : "Login"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default SignInButton;
