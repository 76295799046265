import { Button, OverlayTrigger, Spinner, ToggleButton, ToggleButtonGroup, Tooltip } from "react-bootstrap";
import { useWindowSize } from "usehooks-ts";
import { Playlist, RoundMetadata, Side } from "../utils/types";
import footerStyles from './FooterButton.module.css';
import styles from './RoundPickerToolbar.module.css';
import MapPicker from "./MapPicker";
import { useEffect, useState } from "react";
import { isGeneratorFunction } from "util/types";

const getRoundEndIcon = (roundMetadata: RoundMetadata, active: boolean) => {
  const winner_postfix = roundMetadata.winning_side === "T" ? "_t.svg" : "_ct.svg";

  let svgPath = "/svg/skull" + winner_postfix;
  if (roundMetadata.round_end_reason === "BombDefused") {
    svgPath = "/svg/defused" + winner_postfix;
  } else if (roundMetadata.round_end_reason === "TargetBombed") {
    svgPath = "/svg/exploded" + winner_postfix;
  } else if (roundMetadata.round_end_reason === "TargetSaved") {
    svgPath = "/svg/stopwatch" + winner_postfix;
  }

  return <img src={svgPath} style={{ width: 14, height: 14, opacity: active ? 1.0 : 0.5 }} />;
}

const RoundPickerToolbar = ({ playlist, selectedRound, currentlyFetchingRounds, currentlyFetchedRounds, mr, setSelectedRound }: {
  playlist: Playlist,
  selectedRound: RoundMetadata | undefined,
  currentlyFetchingRounds: Set<string>,
  currentlyFetchedRounds: Set<string>,
  mr: number,
  setSelectedRound: (round: RoundMetadata) => void
}) => {
  const [pageInitialized, setPageInitialized] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(1);

  const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
  const { width, height } = useWindowSize();
  const rounds = playlist.match_id !== undefined ?
    playlist.rounds[playlist.match_id].filter((r) => r.mapname === selectedRound?.mapname) :
    Object.values(playlist.rounds).flat();

  const roundCount = rounds.length;
  const useIcons = (localStorage.getItem('roundPickerStyle-v2') || 'NUMBERS') === 'ICONS';
  const maxRoundsPerPage = (width - 300) / 36;  // Define a maximum number of rounds to display per page

  const maps = playlist.match_id !== undefined ? playlist.rounds[playlist.match_id]?.reduce((acc: string[], round) => {
    if (!acc.includes(round.mapname)) {
      acc.push(round.mapname);
    }
    return acc;
  }, []) : [];

  useEffect(() => {
    if (maxRoundsPerPage < 1) return;
    setTotalPages(Math.ceil(roundCount / maxRoundsPerPage));
    if (selectedRound && !pageInitialized) {
      const index = rounds.findIndex(
        (round: RoundMetadata) =>
          round.match_id === selectedRound.match_id &&
          round.mapname === selectedRound.mapname &&
          round.roundnum === selectedRound.roundnum
      );
      const initialPage = Math.ceil((index + 1) / maxRoundsPerPage);
      setCurrentPage(initialPage);
      setPageInitialized(true);
    }
  }, [maxRoundsPerPage, selectedRound, roundCount, rounds, pageInitialized]);

  // Get the rounds to display on the current page
  const roundsToDisplay = rounds.slice((currentPage - 1) * maxRoundsPerPage, currentPage * maxRoundsPerPage);

  // Define functions for pagination buttons
  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleKeyDown = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  }

  if (selectedRound !== undefined && roundCount > 0) {
    return (
      <>
        {maps.length > 1 && <div className={styles.roundpickerToolbar} style={{ marginRight: '36px' }}>
          <MapPicker
            maps={maps}
            selectedMapName={selectedRound.mapname}
            inRoundPicker={true}
            setSelectedMap={(mapName: string) => {
              setSelectedRound(playlist.rounds[playlist.match_id || '']?.find(round => round.mapname === mapName) || playlist.rounds[playlist.match_id || ''][0]);
            }}
          />
        </div>
        }
        <div className="flex-grow-1">
          &nbsp;
        </div>
        {currentPage > 1 &&
          <Button
            onClick={prevPage}
            onKeyDown={handleKeyDown}
            variant={"outline-secondary"}
            className={footerStyles.button}
            disabled={currentPage === 1}>
            <i className="bi bi-arrow-left"></i>
          </Button>}
        {roundsToDisplay.map((r, index) => {
          const isSelected = r.match_id === selectedRound.match_id && r.mapname === selectedRound.mapname && r.roundnum === selectedRound.roundnum;
          const previousRound = index > 0 ? roundsToDisplay[index - 1] : undefined;
          const useSplitter = playlist.match_id !== undefined && (r.roundnum === mr + 1 || r.roundnum === mr * 2 + 1) || previousRound && previousRound.match_id !== r.match_id;
          const roundId = `${r.match_id}.${r.mapname}.${r.roundnum}`;
          const roundLoading = currentlyFetchingRounds.has(roundId);
          const roundLoaded = currentlyFetchedRounds.has(roundId);

          if (useIcons) {
            return <OverlayTrigger
              key={'top_' + r.roundnum}
              placement={'top'}
              delay={{ show: 50, hide: 100 }}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {r.roundnum}
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => {
                return (
                  <Button
                    {...triggerHandler}
                    ref={ref}
                    id={'round_' + index}
                    onKeyDown={handleKeyDown}
                    key={index}
                    variant={"outline-secondary"}
                    className={`${footerStyles.roundPickerButton} ${isSelected ? (r.winning_side === Side.T ? styles.roundSelectedT : styles.roundSelectedCT) : ""} ${r.winning_side === Side.T ? styles.roundWinT : styles.roundWinCT}`}
                    style={{
                      marginLeft: r.roundnum === mr + 1 ? 18 : 0,
                    }}
                    onClick={(e) => {
                      triggerHandler.onFocus?.(e);
                      setSelectedRound(r);
                    }}
                  >
                    {roundLoading && <Spinner className={"spinner-border-sm"} animation="border" style={{ color: "#ddd" }} />}
                    {!roundLoading &&
                      ((localStorage.getItem('roundPickerStyle-v2') || 'NUMBERS') === 'ICONS' ? getRoundEndIcon(r, isSelected) :
                        <div style={{ fontWeight: roundLoaded ? 800 : 300 }}>{zeroPad(Number(r.roundnum), 2)}</div>)
                    }
                  </Button>
                )
              }}
            </OverlayTrigger>
          } else {
            return <Button
              id={'round_' + index}
              onKeyDown={handleKeyDown}
              key={index}
              variant={"outline-secondary"}
              className={`${useSplitter ? footerStyles.roundPickerSplitterButton : ''} ${footerStyles.roundPickerButton} ${isSelected ? (r.winning_side === Side.T ? styles.roundSelectedT : styles.roundSelectedCT) : ""} ${r.winning_side === Side.T ? styles.roundWinT : styles.roundWinCT}`}
              onClick={(e) => {
                setSelectedRound(r);
              }}
            >
              {roundLoading && <Spinner className={"spinner-border-sm"} animation="border" style={{ color: "#ddd", marginTop: '2px' }} />}
              {!roundLoading &&
                ((localStorage.getItem('roundPickerStyle-v2') || 'NUMBERS') === 'ICONS' ? getRoundEndIcon(r, isSelected) :
                  <div style={{ fontWeight: roundLoaded ? 800 : 300 }}>{zeroPad(Number(r.roundnum), 2)}</div>)
              }
            </Button>
          }
        })}
        {currentPage < totalPages &&
          <Button
            onKeyDown={handleKeyDown}
            onClick={nextPage}
            variant={"outline-secondary"}
            className={footerStyles.button}
            disabled={currentPage === totalPages}>
            <i className="bi bi-arrow-right"></i>
          </Button>}
        <div className="flex-grow-1"></div>
        {maps.length > 1 && <div style={{ marginLeft: '36px', width: maps.length * 36 }}></div>
        }
      </>
    );
  } else {
    return <Spinner className={"spinner-border-sm"} animation="border" style={{ color: "#ddd" }} />
  }
};

export default RoundPickerToolbar;